import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { setState, upsertUser, fetchUser, resetUser } from '../../actions/user'
import Container from '../layout/Container'
import Spinner from '../layout/Spinner'
import WorkerPositions from './WorkerPositions'
import WorkerSelect from '../layout/WorkerSelect'
import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const UserProfile = ({ user, setState, upsertUser, fetchUser, resetUser }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  // On component mount or change in id, fetch the user information from the database

  useEffect(() => {
    if (id !== 'new') fetchUser({ _id: id })
    else resetUser()
  }, [id, fetchUser, resetUser])

  // On user change, update state without committing changes to the database

  const handleChange = e =>
    setState({
      ...user,
      [e.target.id]: e.target.value
    })

  // If user saves changes, update the user in the database

  const handleSubmit = async () => {
    const res = await upsertUser({
      ...user,
      positions: [...user.positions.filter(p => p && p.position.name)]
    })
    console.log(res)
    navigate(`/workers/${res._id}`)
  }

  const addPosition = () =>
    setState({
      ...user,
      positions: [
        ...user.positions,
        {
          _id: new ObjectId().toString(),
          position: {},
          rate: ''
        }
      ]
    })

  const updatePosition = (e, position) =>
    setState({
      ...user,
      positions: user.positions.map(p => (p._id === position._id ? { ...p, [e.target.id]: e.target.value } : p))
    })

  const removePosition = id => {
    console.log(id)
    setState({
      ...user,
      positions: [...user.positions.filter(p => p._id !== id)]
    })
  }

  return (
    <>
      {id !== 'new' && !user._id && <Spinner />}
      <Container>
        <form>
          <div>
            <h1 class='text-center text-2xl font-bold leading-loose tracking-normal text-gray-700'>
              {id === 'new' ? 'Add New Worker' : 'Edit Worker Profile'}
            </h1>

            <div class='mt-8'>
              <div>
                <h3 class='text-lg font-medium leading-6 text-gray-900'>Worker Information</h3>
                <p class='mt-1 text-sm leading-5 text-gray-500'>Enter the worker's contact information</p>
              </div>
              <div class='row-gap-6 col-gap-4 mt-6 grid grid-cols-1 sm:grid-cols-6'>
                <div class='sm:col-span-3'>
                  <label for='first_name' class='block text-sm font-medium leading-5 text-gray-700'>
                    First name
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='first_name'
                      onChange={handleChange}
                      value={user.first_name || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-3'>
                  <label for='last_name' class='block text-sm font-medium leading-5 text-gray-700'>
                    Last name
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='last_name'
                      onChange={handleChange}
                      value={user.last_name || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-3'>
                  <label for='email' class='block text-sm font-medium leading-5 text-gray-700'>
                    Email address
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='email'
                      onChange={handleChange}
                      value={user.email || ''}
                      type='email'
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-3'>
                  <label for='phone' class='block text-sm font-medium leading-5 text-gray-700'>
                    Phone
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='phone'
                      onChange={handleChange}
                      value={user.phone || ''}
                      type='phone'
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class='mt-8 border-t border-gray-200 pt-8'>
              <div>
                <h3 class='text-lg font-medium leading-6 text-gray-900'>Address</h3>
                <p class='mt-1 text-sm leading-5 text-gray-500'>Enter the worker's address information</p>
              </div>
              <div class='row-gap-6 col-gap-4 mt-6 grid grid-cols-1 sm:grid-cols-6'>
                <div class='sm:col-span-4'>
                  <label for='street' class='block text-sm font-medium leading-5 text-gray-700'>
                    Street address
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='street'
                      onChange={handleChange}
                      value={user.street || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-2'>
                  <label for='city' class='block text-sm font-medium leading-5 text-gray-700'>
                    City
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='city'
                      onChange={handleChange}
                      value={user.city || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-2'>
                  <label for='province' class='block text-sm font-medium leading-5 text-gray-700'>
                    Province
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='province'
                      onChange={handleChange}
                      value={user.province || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-2'>
                  <label for='postal_code' class='block text-sm font-medium leading-5 text-gray-700'>
                    Postal Code
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <input
                      id='postal_code'
                      onChange={handleChange}
                      value={user.postal_code || ''}
                      class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />
                  </div>
                </div>

                <div class='sm:col-span-2'>
                  <label for='country' class='block text-sm font-medium leading-5 text-gray-700'>
                    Country / Region
                  </label>
                  <div class='mt-1 rounded-md shadow-sm'>
                    <select
                      id='country'
                      onChange={handleChange}
                      value={user.country || ''}
                      class='form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    >
                      <option>Canada</option>
                      <option>United States</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='mt-8 border-t border-gray-200 pt-8'>
            <div>
              <h3 class='text-lg font-medium leading-6 text-gray-900'>Positions</h3>
              <p class='mt-1 text-sm leading-5 text-gray-500'>Add the different positions this person can fill</p>
            </div>
            <div class='row-gap-1 col-gap-4 my-6 grid grid-cols-1 sm:grid-cols-6'>
              <div class='sm:col-span-2'>
                <label for='name' class='block text-sm font-medium leading-5 text-gray-700'>
                  Title
                </label>
              </div>

              <div class='sm:col-span-2'>
                <label for='rate' class='block text-sm font-medium leading-5 text-gray-700'>
                  Pay Rate
                </label>
              </div>
              <div class='sm:col-span-2'></div>

              <WorkerPositions positions={user.positions || []} updatePosition={updatePosition} removePosition={removePosition} />
            </div>
            <div class='cursor-pointer text-indigo-700 hover:underline' onClick={addPosition}>
              + Add Position
            </div>
          </div>

          <div class='mt-8 border-t border-gray-200 pt-5'>
            <div class='flex justify-start'>
              <span class='inline-flex rounded-md shadow-sm'>
                <button
                  onClick={handleSubmit}
                  type='button'
                  class='focus:shadow-outline-indigo inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:opacity-75 focus:border-indigo-700 focus:outline-none active:bg-indigo-700'
                >
                  Save
                </button>
              </span>
              <span class='ml-3  inline-flex rounded-md shadow-sm'>
                <Link
                  to='/workers'
                  type='button'
                  class='focus:shadow-outline-blue rounded-md border border-gray-300 px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800'
                >
                  Cancel
                </Link>
              </span>
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user.user
})

export default connect(mapStateToProps, { setState, upsertUser, fetchUser, resetUser })(UserProfile)
