import React, { useEffect, useState } from 'react';

const SecondaryButton = ({ id, onClick, children, style, loading, classes = '' }) => {
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setSpinner(loading);
  }, [loading]);

  const handleClick = async (e) => {
    setSpinner(true);
    await onClick(e);
    setSpinner(false);
  };

  const defaultClasses = {
    root:
      'inline-flex items-center px-4 py-2 border border-gray-300 hover:border-gray-400 text-sm leading-5 font-medium rounded-md text-gray-500 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
  };

  const prefixes = classes.split(' ').map((cls) => cls.split('-')[0]);

  classes = {
    root:
      defaultClasses.root
        .split(' ')
        .filter((cls) => !prefixes.includes(cls.split('-')[0]))
        .join(' ') +
      ' ' +
      classes,
  };

  return (
    <button id={id} onClick={handleClick} style={style} type='button' class={classes.root}>
      {spinner && <span class='spinner-button -ml-2 mr-2'></span>}
      {children}
    </button>
  );
};

export default SecondaryButton;
