import { SET_USER, SET_USERS, UPDATE_USER, RESET_USER, ADD_USER, UPDATE_SELF, SET_USERS_FOR_POSITION } from '../actions/types';

const defaultState = {
  user: {
    positions: [],
  },
  users: [],
  usersByPosition: {}
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_USER:
    case UPDATE_SELF:
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users.filter((user) => user._id !== payload._id), payload],
      };
    case SET_USERS:
      return {
        ...state,
        users: [...payload],
      };
    case RESET_USER:
      return {
        ...state,
        user: { ...defaultState.user },
      };
    case SET_USERS_FOR_POSITION: 
      return {
        ...state,
        usersByPosition: {
          ...state.usersByPosition,
          [payload.positionId]: payload.users
        }
      }
    default:
      return state;
  }
};
