import axios from './axios'
import { SET_AGENCY, SET_AGENCIES, UPSERT_AGENCY, UPSERT_AGENCIES, RESET_AGENCY, DELETE_AGENCY } from './types'
import { setAlert } from './alert'

export const fetchAgencies = filter => async dispatch => {
  try {
    const res = await axios.get('/api/agency', { params: filter })
    dispatch({
      type: SET_AGENCIES,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchAgency = filter => async dispatch => {
  try {
    const res = await axios.get('/api/agency', { params: filter })
    dispatch({
      type: SET_AGENCY,
      payload: res.data[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchAgencyById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/agency/${id}`)
    dispatch({
      type: SET_AGENCY,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const setState = agency => async dispatch => {
  dispatch({
    type: SET_AGENCY,
    payload: agency
  })
}

export const setAgencies = agencies => async dispatch => {
  dispatch({
    type: SET_AGENCIES,
    payload: agencies
  })
}

export const resetAgency = () => async dispatch => {
  dispatch({
    type: RESET_AGENCY
  })
}

export const upsertAgency = agency => async dispatch => {
  try {
    await axios.post(`/api/agency`, agency)
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertAgencies = agencies => async dispatch => {
  try {
    let res = await Promise.all(agencies.map(async agency => await axios.post(`/api/agency`, agency)))
    let data = res.map(r => r.data)
    console.log(res)
    dispatch({
      type: UPSERT_AGENCIES,
      payload: data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const deleteAgency = id => async dispatch => {
  try {
    await axios.delete(`/api/agency/${id}`)
    dispatch({
      type: DELETE_AGENCY,
      payload: { id }
    })
    dispatch(setAlert('success', 'Successfully deleted the event'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
