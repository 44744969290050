import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { setState, upsertEvent, fetchEvent, resetEvent, setShift, removeShift, fetchOrCreateEvents, fetchOrCreateEvent } from '../../actions/event'
import { getRecord } from '../../actions/tripleseat'
import { deleteLog } from '../../actions/log'
import Container from '../layout/Container'
import Spinner from '../layout/Spinner'
import axios from 'axios'
import EventTabs from './EventTabs'
import EventDetails from './EventDetails'
import EventButtons from './EventButtons'
import Shifts from './Shifts'
import NewShifts from './Shifts/NewShifts'
import { Fragment } from 'react'
import FileList from './Requests/FileList'
import InvoiceFileList from './Invoices/InvoiceFileList'
import EventHistory from './EventHistory/EventHistory'
import moment from 'moment'
import Instawork from './Instawork/Instawork'
import Profit from './Profit/Profit'
import Tripleseat from './Tripleseat/Tripleseat'
import TasksPage from './Tasks/TasksPage'

const Event = ({ event, setState, upsertEvent, fetchEvent, resetEvent, setShift, removeShift, getRecord, fetchOrCreateEvent, deleteLog }) => {
  const { id, tid } = useParams()
  const [currentTab, setCurrentTab] = useState('Event Details')

  useEffect(() => {
    if (id && id !== 'new') fetchEvent({ _id: id })
    else if (tid) fetchOrCreateEvent(tid)
    else resetEvent()
  }, [id, fetchEvent, resetEvent])

  // On user change, update state without committing changes to the database

  const handleChange = e =>
    setState({
      ...event,
      [e.target.id]: moment.isDate(e.target.value) ? e.target.value.toISOString() : e.target.value
    })

  // If user saves changes, update the event in the database

  const handleSubmit = () => upsertEvent(event)
  const handleTabChange = e => setCurrentTab(e.target.innerHTML)

  const EventTabItems = {
    'Event Details': <EventDetails event={event} handleChange={handleChange} />,
    'Shifts': <NewShifts event={event} handleChange={handleChange} setShift={setShift} removeShift={removeShift} />,
    'Tasks': <TasksPage event={event} handleChange={handleChange} />,
    'Requests': <FileList event={event} upsertEvent={upsertEvent} />,
    'Profit/Loss': <Profit event={event} />,
    'Instawork': <Instawork event={event} upsertEvent={upsertEvent} />,
    'Tripleseat': <Tripleseat event={event} />,
    'Invoices': <InvoiceFileList event={event} upsertEvent={upsertEvent} />,
    'History': <EventHistory logs={event.logs} deleteLog={deleteLog} />
  }

  return (
    <>
      {id && id !== 'new' && !event._id && <Spinner />}
      <Container>
        <form>
          <div>
            <h1 class='text-center text-2xl font-bold leading-loose tracking-normal text-gray-700'>{event.name || 'New Event'}</h1>
            <EventTabs handleTabChange={handleTabChange} currentTab={currentTab} tabs={Object.keys(EventTabItems)} />
            {EventTabItems[currentTab]}
          </div>
          <EventButtons handleSubmit={handleSubmit} />
        </form>
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  event: state.event.event
})

export default connect(mapStateToProps, {
  setState,
  upsertEvent,
  fetchEvent,
  resetEvent,
  setShift,
  removeShift,
  getRecord,
  fetchOrCreateEvent,
  deleteLog
})(Event)
