import { SET_EVENTS, SET_SHIFTS, UPSERT_SHIFTS } from './types';
import { setAlert } from './alert';
import axios from 'axios';

export const fetchYaneStaffingEvents =
  (filter = {}) =>
  async (dispatch) => {
    try {
      const res = await axios.get('/api/shift/agency/yanestaffing', { params: filter });
      const shifts = res.data;
      console.log(shifts);
      const eventsObj = shifts.reduce((res, shift) => {
        const event = shift.event;
        res[event._id] = { ...shift.event, label: `PO# ${shift.event.event_id} - ${shift.event.name}`, value: shift.event._id };
        return res;
      }, {});
      dispatch({
        type: SET_EVENTS,
        payload: Object.values(eventsObj),
      });
      dispatch({
        type: SET_SHIFTS,
        payload: shifts,
      });
    } catch (err) {
      if (err.response) dispatch(setAlert('error', err.response.data.msg));
    }
  };

export const upsertYaneShifts = (shifts) => async (dispatch) => {
  try {
    let res = await Promise.all(shifts.map(async (shift) => await axios.post(`/api/shift/agency/yanestaffing`, shift)));
    let data = res.map((r) => r.data);
    dispatch({
      type: UPSERT_SHIFTS,
      payload: data,
    });
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'));
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg));
  }
};

export const uploadInvoice = (data) => async (dispatch) => {
  try {
    let res = await axios.post(`/api/gapi/yane`, data);
    console.log(res);
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg));
  }
};
