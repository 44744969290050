import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PrimaryButton from '../../layout/Buttons/PrimaryButton'
import { createInstaworkEvent, fetchInstaworkShifts } from '../../../actions/instawork'
import InstaworkEventsSelect from '../../layout/InstaworkEventsSelect'
import InstaworkShifts from './InstaworkShifts'
import moment from 'moment'

const Instawork = ({ event, createInstaworkEvent, fetchInstaworkShifts, upsertEvent }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (event.iw_event_id) fetchInstaworkShifts(event.iw_event_id)
  }, [])

  const handleClick = async () => {
    setLoading(true)
    await createInstaworkEvent(event)
    setLoading(false)
  }

  const handleInstaworkEventChange = e => {
    console.log('handleInstaworkEventChange')
    const iw_event_id = e.target.value ? e.target.value._id : ''

    upsertEvent({
      ...event,
      iw_event_id
    })

    // @THIS IS WHERE YOU LEFT OFF
    fetchInstaworkShifts(iw_event_id)
  }

  return (
    <div>
      <h2 class='font-bold'>Link Event to Instawork</h2>
      <p class='text-sm text-gray-700'>
        Either select an existing Instawork event from the dropdown below or click the button to create a new event in Instawork
      </p>

      <div class='mt-4 flex w-2/3 items-center'>
        {/* <div class='w-4/6'>
          <InstaworkEventsSelect isClearable={true} onChange={handleInstaworkEventChange} value={{ _id: event.iw_event_id }} />
        </div> */}
        <div class='w-2/6 pl-1'>
          {!event.iw_event_id && (
            <PrimaryButton loading={loading} onClick={handleClick}>
              Create Instawork Event
            </PrimaryButton>
          )}
          {event.iw_event_id && (
            <a
              class='focus:shadow-outline-indigo inline-flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:opacity-75 focus:border-indigo-700 focus:outline-none sm:text-sm sm:leading-5'
              target='_blank'
              href={`https://app.instawork.com/dashboard/events/${event.iw_event_id}`}
            >
              View Instawork Event
            </a>
          )}
        </div>
      </div>

      {event.iw_event_id && <InstaworkShifts />}
    </div>
  )
}

const mapStateToProps = state => ({
  event: state.event.event
})

export default connect(mapStateToProps, { createInstaworkEvent, fetchInstaworkShifts })(Instawork)
