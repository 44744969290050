import React from 'react';

const MicrosoftLoginButton = ({ onClick }) => {
  return (
    <div className='mt-4 flex h-10 w-full cursor-pointer items-center justify-center rounded-md bg-gray-800 text-white hover:opacity-75 ' onClick={onClick}>
      <svg xmlns='http://www.w3.org/2000/svg' className='mx-3' width='21' height='21' viewBox='0 0 21 21'>
        <title>MS-SymbolLockup</title>
        <rect x='1' y='1' width='9' height='9' fill='#f25022' />
        <rect x='1' y='11' width='9' height='9' fill='#00a4ef' />
        <rect x='11' y='1' width='9' height='9' fill='#7fba00' />
        <rect x='11' y='11' width='9' height='9' fill='#ffb900' />
      </svg>
      <div>Sign in with Microsoft</div>
    </div>
  );
};

export default MicrosoftLoginButton;
