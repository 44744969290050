import React, { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import SecondaryButton from '../../layout/Buttons/SecondaryButton';
import moment from 'moment';
import Tasks from './Tasks';
import Notes from './Notes';

const TasksPage = ({ event, handleChange }) => {
  return (
    <div className='mt-10 grid grid-cols-1'>
      <Tasks handleChange={handleChange} tasks={event.tasks} />
      {/* <Notes handleChange={handleChange} notes={event.notes} /> */}
    </div>
  );
};

export default TasksPage;
