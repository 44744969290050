import { SET_EVENT, SET_EVENTS, UPSERT_EVENT, FETCH_EVENT, RESET_EVENT, DELETE_EVENT, ADD_EVENT_SHIFT, SET_EVENT_SHIFT, SET_EVENT_SHIFTS, REMOVE_EVENT_SHIFT, DELETE_LOG } from '../actions/types';

const defaultState = {
  event: {
    company: 'Encore Catering',
    shifts: [],
    requests: [],
    logs: [],
    isNew: true,
  },
  initialState: {},
  events: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_EVENT:
      return {
        ...state,
        event: {
          ...payload,
        },
      };
    case FETCH_EVENT:
    case UPSERT_EVENT:
      return {
        ...state,
        event: {
          ...payload,
        },
        initialState: {
          ...payload,
        },
      };
    case DELETE_EVENT:
      return {
        ...state,
        event: { ...defaultState.event },
        events: [...state.events.filter((event) => event._id !== payload.id)],
      };
    case RESET_EVENT:
      return {
        ...state,
        event: { ...defaultState.event },
      };
    case SET_EVENTS:
      return {
        ...state,
        events: [...payload],
      };
    case ADD_EVENT_SHIFT:
      return {
        ...state,
        event: {
          ...state.event,
          shifts: [...state.event.shifts, payload],
        },
      };
    case SET_EVENT_SHIFT:
      return {
        ...state,
        event: {
          ...state.event,
          shifts: state.event.shifts.map((item) => (item._id !== payload._id ? item : payload)),
        },
      };
    case SET_EVENT_SHIFTS:
      return {
        ...state,
        event: {
          ...state.event,
          shifts: [...payload],
        },
      };
    case REMOVE_EVENT_SHIFT:
      return {
        ...state,
        event: {
          ...state.event,
          shifts: state.event.shifts.filter((item) => item._id !== payload),
        },
      };
    case DELETE_LOG:
      return {
        ...state,
        event: {
          ...state.event,
          logs: state.event.logs.filter((log) => log._id !== payload._id),
        },
      };
    default:
      return state;
  }
};
