import { SET_INSTAWORK_EVENTS, ADD_INSTAWORK_EVENT, SET_INSTAWORK_SHIFTS } from '../actions/types';

const defaultState = {
  events: [],
  shifts: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_INSTAWORK_EVENTS:
      return {
        ...state,
        events: [...payload],
      };
    case ADD_INSTAWORK_EVENT:
      return {
        ...state,
        events: [...state.events, payload],
      };
    case SET_INSTAWORK_SHIFTS:
      return {
        ...state,
        shifts: [...payload],
      };
    default:
      return state;
  }
};
