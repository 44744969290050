import React, { useState } from 'react'
import Input from '../../layout/Input'
import PositionSelect from '../../layout/PositionSelect'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AgencySelect from '../../layout/AgencySelect'
import moment from 'moment'

import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const AddShiftsModal = ({ event, shifts, setModalState, handleChange }) => {
  const [shift, setShift] = useState({ event: event._id, gratuity: 0, additional_costs: 0 })
  const [quantity, setQuantity] = useState(1)
  const [error, setError] = useState('')

  const handleSubmit = () => {
    const newShifts = Array.from({ length: quantity })
      .fill(shift)
      .map(shift => ({ ...shift, _id: new ObjectId().toString() }))
    // add the new shifts to the event - this only updates state - database is only updated one event is saved.
    handleChange({
      target: {
        id: 'shifts',
        value: [...shifts, ...newShifts]
      }
    })
    setModalState(false)
  }

  const handleShiftChange = e => {
    setError('')
    setShift({
      ...shift,
      [e.target.id]: e.target.value
    })
  }

  const handleShiftTimeChange = (date, id) => {
    // 'expected_start_time'
    setError('')

    if (id === 'expected_end_time' && !shift.expected_start_time) {
      setError('You must first select a start time for the shift!')
    }

    const eventDate = moment(event.event_date)
    let revisedDate = moment(date).set({
      year: eventDate.get('year'),
      month: eventDate.get('month'),
      date: eventDate.get('date')
    })

    // need to ensure that the expected end time is greater than the expected start time
    if (id === 'expected_end_time' && moment(shift.expected_start_time) > revisedDate) {
      revisedDate = revisedDate.add(1, 'days')
    }

    console.log(revisedDate)

    handleShiftChange({ target: { id, value: revisedDate.toDate() } })
  }

  return (
    <>
      <div class='z-40 fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center'>
        <div onClick={() => setModalState(false)} class='fixed inset-0 transition-opacity'>
          <div class='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        <div
          class='bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div>
            <div class='my-3 sm:mt-5'>
              <h3 class='mb-5 text-center text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                Add shifts
              </h3>
              <div class='mt-2'>
                <div>
                  <div class='mb-4'>
                    <label for='position' class='block text-sm font-medium leading-5 text-gray-700'>
                      Position
                    </label>
                    <div class='mt-1 rounded-md '>
                      <PositionSelect id='position' value={shift.position || {}} onChange={handleShiftChange} />
                    </div>
                  </div>

                  <div class='mb-4'>
                    <label for='agency' class='block text-sm font-medium leading-5 text-gray-700'>
                      Agency (optional)
                    </label>
                    <div class='mt-1 rounded-md '>
                      <AgencySelect id='agency' value={shift.agency || {}} onChange={handleShiftChange} />
                    </div>
                  </div>

                  <div class='mb-4'>
                    <label for='agency' class='block text-sm font-medium leading-5 text-gray-700'>
                      Number of Staff
                    </label>
                    <div class='mt-1 rounded-md shadow-sm'>
                      <input
                        id={quantity}
                        type='number'
                        min='0'
                        max='20'
                        onChange={e => setQuantity(e.target.value)}
                        value={quantity}
                        class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div class='flex justify-between mb-4 mr-1'>
                    <div>
                      <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
                        Start Time
                      </label>
                      <div class='mt-1 rounded-md '>
                        <DatePicker
                          id='expected_start_time'
                          className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                          popperPlacement='top'
                          selected={shift.expected_start_time ? new Date(shift.expected_start_time) : ''}
                          onChange={date => handleShiftTimeChange(date, 'expected_start_time')}
                          dateFormat='h:mm aa'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                        />
                      </div>
                    </div>
                    <div class='ml-1'>
                      <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
                        End Time
                      </label>
                      <div class='mt-1 rounded-md '>
                        <DatePicker
                          id='expected_end_time'
                          className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                          popperPlacement='top'
                          selected={shift.expected_end_time ? new Date(shift.expected_end_time) : ''}
                          onChange={date => handleShiftTimeChange(date, 'expected_end_time')}
                          dateFormat='h:mm aa'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='mt-5 sm:mt-6'>
            {error && <p class='text-red-600 text-sm font-semibold text-center mb-5'>{error}</p>}
            <span class='flex w-full rounded-md shadow-sm'>
              <button
                onClick={handleSubmit}
                type='button'
                class='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5'
              >
                Submit
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddShiftsModal
