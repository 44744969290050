import React, { useState } from 'react'
import { connect } from 'react-redux'
import PositionSelect from '../shifts/PositionSelect'
import Input from '../layout/Input'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import TextArea from '../layout/TextArea'
import ListOfShifts from './ListOfShifts'
import { addShift, setShift, removeShift } from '../../actions/event'

import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const Shifts = ({ event, addShift, setShift, removeShift }) => {
  const shifts = event.shifts

  const addNewShift = () => {
    addShift({ _id: new ObjectId().toString(), event: event._id })
  }

  const updateShift = (e, shift) => {
    setShift({
      ...shift,
      [e.target.id]: e.target.value
    })
  }

  return (
    <div>
      {/* <div class='mt-8 border-t border-gray-200 pt-5'>
        <h3 class='text-lg leading-6 font-medium text-gray-900'>Staff Notes</h3>
        <p class='mt-1 text-sm leading-5 text-gray-500'>Please include any general notes that you would like all the staff to know.</p>
        <TextArea id='staff_notes' handleChange={handleChange} value={event.staff_notes} />
      </div> */}
      <h3 class='text-lg leading-6 font-medium text-gray-900'>Staff Required</h3>
      <p class='mt-1 mb-6 text-sm leading-5 text-gray-500'>Please indicate the type and number of staff required.</p>
      <ListOfShifts shifts={shifts || []} updateShift={updateShift} removeShift={removeShift} />
      <div class='flex'>
        <div class='mt-4 hover:underline cursor-pointer text-indigo-700 mr-6' onClick={addNewShift}>
          + Add Shift
        </div>
        <div class='mt-4 hover:underline cursor-pointer text-indigo-700' onClick={addNewShift}>
          + Add Multiple Shifts
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  event: state.event.event
})

export default connect(mapStateToProps, { addShift, setShift, removeShift })(Shifts)
