import { SET_POSITION, SET_POSITIONS, UPSERT_POSITION, RESET_POSITION, DELETE_POSITION } from '../actions/types';

const defaultState = {
  position: {},
  positions: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_POSITION:
    case UPSERT_POSITION:
      return {
        ...state,
        position: {
          ...payload,
        },
      };
    case DELETE_POSITION:
      return {
        ...state,
        position: { ...defaultState.position },
        positions: [...state.positions.filter((position) => position._id !== payload.id)],
      };
    case RESET_POSITION:
      return {
        ...state,
        position: { ...defaultState.position },
      };
    case SET_POSITIONS:
      return {
        ...state,
        positions: [...payload],
      };
    default:
      return state;
  }
};
