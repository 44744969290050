import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { calculateProfit } from '../../../actions/event';

const Profit = ({ event, calculateProfit }) => {
  useEffect(() => {
    console.log('getting proposal items');
    calculateProfit(event);
  }, []);

  const shifts = event.shifts || [];
  const costs = event.costs || [];

  console.log({ costs });

  const hasScheduledInstaworkShifts = shifts.some(shift => shift.agency?.name === 'Instawork');
  const hasBilledInstaworkShifts = costs.some(shift => shift.source === 'Instawork');
  const hasScheduledYaneShifts = shifts.some(shift => shift.agency?.name === 'Yane Staffing');
  const hasBilledYaneShifts = costs.some(shift => shift.source === 'Yane Staffing');

  let revenue = 0;
  let totalNumberOfWorkers = 0;
  let totalHours;
  let cost = 0;
  let profit = 0;

  return (
    <div className='sm:col-span-2'>
      <dt className='mt-4 text-lg leading-6 font-medium text-indigo-700 mb-4'>Revenue</dt>
      <div class='flex font-semibold text-sm border-b'>
        <div class='w-1/6'>Source</div>
        <div class='w-1/6'>Position</div>
        <div class='w-1/6'>Quantity</div>
        <div class='w-1/6'>Hours</div>
        <div class='w-1/6'>Rate</div>
        <div class='w-1/6'>Total</div>
      </div>
      <div>
        {event.proposal.map(item => {
          revenue += +item.total;
          return (
            <div class='flex text-sm py-1'>
              <div class='w-1/6'>Tripleseat Proposal</div>
              <div class='w-1/6'>{item.position}</div>
              <div class='w-1/6'>{item.quantity}</div>
              <div class='w-1/6'>{item.hours ? Number(item.hours * item.quantity).toFixed(2) : 0}</div>
              <div class='w-1/6'>$ {item.rate}</div>
              <div class='w-1/6'>$ {item.total ? Number(item.total).toFixed(2) : 0}</div>
            </div>
          );
        })}
        <div class='flex font-semibold text-sm border-t py-1'>
          <div class='w-1/6'>Total Revenue</div>
          <div class='w-1/6'></div>
          <div class='w-1/6'>{event.proposal.reduce((acc, item) => acc + Number(item.quantity), 0)} workers</div>
          <div class='w-1/6'>{event.proposal.reduce((acc, item) => acc + Number(item.hours * item.quantity), 0)} hrs</div>
          <div class='w-1/6'></div>
          <div class='w-1/6'>$ {revenue}</div>
        </div>
      </div>
      <dt className='mt-4 text-lg leading-6 font-medium text-indigo-700 mb-4'>Expenses</dt>
      <div class='text-red-600 font-medium mb-4 text-sm'>
        {hasScheduledInstaworkShifts && !hasBilledInstaworkShifts && <div>This calculation is missing Instawork Shifts</div>}
        {hasScheduledYaneShifts && !hasBilledYaneShifts && <div>This calculation is missing Yane Staffing Shifts</div>}
      </div>
      <div class='flex font-semibold text-sm border-b'>
        <div class='w-1/6'>Source</div>
        <div class='w-1/6'>Position</div>
        <div class='w-1/6'>Quantity</div>
        <div class='w-1/6'>Hours</div>
        <div class='w-1/6'>Rate</div>
        <div class='w-1/6'>Total</div>
      </div>
      <div>
        {event.costs?.map(item => {
          cost += +item.total;
          return (
            <div class='flex text-sm py-1'>
              <div class='w-1/6'>{item.source}</div>
              <div class='w-1/6'>{item.position}</div>
              <div class='w-1/6'>{item.quantity}</div>
              <div class='w-1/6'>{item.hours ? Number(item.hours).toFixed(1) : '0.0'}</div>
              <div class='w-1/6'>$ {item.rate}</div>
              <div class='w-1/6'>$ {item.total ? Number(item.total).toFixed(2) : 0}</div>
            </div>
          );
        })}
        <div class='flex font-semibold text-sm border-t py-1'>
          <div class='w-1/6'>Total Expenses</div>
          <div class='w-1/6'></div>
          <div class='w-1/6'>{event.costs?.reduce((acc, item) => acc + Number(item.quantity), 0)} workers</div>
          <div class='w-1/6'>{event.costs?.reduce((acc, item) => acc + Number(item.hours), 0)} hrs</div>
          <div class='w-1/6'></div>
          <div class='w-1/6'>$ {Number(cost).toFixed(2)}</div>
        </div>
      </div>
      <dt className='mt-4 text-lg leading-6 font-medium text-indigo-700 mb-4'>Profit</dt>
      <div class='flex text-md py-1 font-semibold'>
        <div class='w-1/6'>
          <div class='p-4 border rounded'>$ {(revenue - cost).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { calculateProfit })(Profit);
