import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';

const Register = ({ setAlert, register }) => {
  const [formData, setFormData] = useState({});

  const { first_name, last_name, email, password, password2 } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.id]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({ first_name, last_name, email, password });
    }
  };

  return (
    <div class='relative flex flex-col md:py-12 sm:px-6 lg:px-8'>
      <div class='z-10 mt-24 sm:mt-40 sm:mx-auto sm:w-full sm:max-w-md'>
        <div class='bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10'>
          <h2 class='mb-12 mt-8 text-center text-3xl leading-9 font-extrabold text-gray-900'>Add a new user</h2>
          <form onSubmit={onSubmit}>
            <div class=''>
              <label for='first_name' class='block text-sm font-medium leading-5 text-gray-700'>
                First Name
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='first_name'
                  required
                  value={first_name}
                  onChange={onChange}
                  class='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-4'>
              <label for='last_name' class='block text-sm font-medium leading-5 text-gray-700'>
                Last Name
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='last_name'
                  required
                  value={last_name}
                  onChange={onChange}
                  class='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-4'>
              <label for='email' class='block text-sm font-medium leading-5 text-gray-700'>
                Email Address
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='email'
                  type='email'
                  required
                  value={email}
                  onChange={onChange}
                  class='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-4'>
              <label for='password' class='block text-sm font-medium leading-5 text-gray-700'>
                Password
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='password'
                  type='password'
                  required
                  value={password}
                  onChange={onChange}
                  class='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-4'>
              <label for='password' class='block text-sm font-medium leading-5 text-gray-700'>
                Confirm Password
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='password2'
                  type='password'
                  required
                  value={password2}
                  onChange={onChange}
                  class='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-6'>
              <span class='block w-full rounded-md shadow-sm'>
                <button
                  type='submit'
                  class='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
                >
                  Register
                </button>
              </span>
            </div>
          </form>
          <p class='mt-6 block text-center text-sm font-medium leading-5 text-gray-700'>
            Already have an account?{' '}
            <Link class='font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150' to='/login'>
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setAlert, register };

export default connect(mapStateToProps, mapDispatchToProps)(Register);
