import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../layout/Input'
import ListOfShifts from './ListOfShifts'
import TextArea from '../layout/TextArea'
import GenericSelect from '../layout/GenericSelect'
import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const EventDetails = ({ handleChange, event, shifts, setShifts }) => {
  return (
    <div>
      <div>
        <h3 class='text-lg leading-6 font-medium text-gray-900'>Event Details</h3>
        <p class='mt-1 text-sm leading-5 text-gray-500'>Please specify the details for the event</p>
      </div>

      <div class='mt-6 grid grid-cols-1 row-gap-3 col-gap-4 sm:grid-cols-6'>
        <div class='sm:col-span-3'>
          <Input id='name' label='Event Name' handleChange={handleChange} value={event.name} />
        </div>
        <div class='sm:col-span-3'>
          <label for='company' class='block text-sm font-medium leading-5 text-gray-700'>
            Company
          </label>
          <GenericSelect
            id='company'
            onChange={handleChange}
            value={event.company}
            isClearable={false}
            options={[
              { label: 'Encore Catering', value: 'Encore Catering' },
              { label: 'F + B Kosher', value: 'F + B Kosher' }
            ]}
          />
        </div>

        <div class='sm:col-span-3'>
          <label for='date' class='block text-sm font-medium leading-5 text-gray-700'>
            Event Date/Time
          </label>
          <div class='mt-1 rounded-md '>
            <DatePicker
              id='event_date'
              className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              selected={event.event_date ? new Date(event.event_date) : ''}
              onChange={date => handleChange({ target: { id: 'event_date', value: date } })}
              dateFormat='MMMM d, yyyy'
            />
          </div>
        </div>

        <div class='sm:col-span-3'>
          <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
            Time of Party
          </label>
          <div class='mt-1 rounded-md '>
            <DatePicker
              id='time_of_party'
              className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              selected={event.time_of_party ? new Date(event.time_of_party) : ''}
              onChange={date => handleChange({ target: { id: 'time_of_party', value: date } })}
              dateFormat='h:mm aa'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
            />
          </div>
        </div>

        <div class='sm:col-span-3'>
          <Input id='client_name' label='Client Name' handleChange={handleChange} value={event.client_name} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='event_type' label='Type of Party' handleChange={handleChange} value={event.event_type} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='venue' label='Venue' handleChange={handleChange} value={event.venue} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='address' label='Venue Address' handleChange={handleChange} value={event.address} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='guest_count' label='Number of Guests' handleChange={handleChange} value={event.guest_count} />
        </div>

        <div class='sm:col-span-3'>
          <Input id='type_of_service' label='Type of Service' handleChange={handleChange} value={event.type_of_service} />
        </div>
        {/* </div> */}
        {/* <div class='mt-8 border-t border-gray-200 pt-5 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6'> */}
        <div class='sm:col-span-3'>
          <Input id='contact_name' label='Contact at Location' handleChange={handleChange} value={event.contact_name} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='contact_phone' label='Tel # at Location' handleChange={handleChange} value={event.contact_phone} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='encore_contact' label='Encore Contact' handleChange={handleChange} value={event.encore_contact} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='intersection' label='Intersection' handleChange={handleChange} value={event.intersection} />
        </div>
        <div class='sm:col-span-3'>
          <Input id='travel_time' label='Travel Time' handleChange={handleChange} value={event.travel_time} />
        </div>
      </div>

      <div class='mt-8 border-t border-gray-200 pt-5'>
        <h3 class='text-lg leading-6 font-medium text-gray-900'>Scheduling Notes</h3>
        <p class='mt-1 text-sm leading-5 text-gray-500'>
          Please include any notes for the person who will be responsible for staffing the event. These notes will not be seen by the workers.
        </p>
        <TextArea id='scheduling_notes' handleChange={handleChange} value={event.scheduling_notes} />
      </div>
    </div>
  )
}

export default EventDetails
