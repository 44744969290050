import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SecondaryButton from '../../layout/Buttons/SecondaryButton';
import PrimaryButtonLink from '../../layout/Buttons/PrimaryButtonLink';
import { setAlert } from '../../../actions/alert';
import { PaperClipIcon } from '@heroicons/react/solid';
import moment from 'moment';
import axios from '../../../actions/axios';

const FileListItem = ({ request, event, agencies, setAlert }) => {
  const [agency, setAgency] = useState('');
  const [recipient, setRecipient] = useState('');
  const [sending, setSending] = useState(false);
  const [isRevision, setIsRevision] = useState(false);

  useEffect(() => {
    const agency = agencies.find(agency => request.agency == agency.label);
    console.log(agency);
    if (agency) {
      setAgency(agency);
      setRecipient(agency.email || '');
    }
  }, [agencies, request.agency]);

  console.log({ agencies, agency, request });

  const sendEmail = async () => {
    if (!recipient) return setAlert('error', 'You must provide an email address for where to send the request!');
    else if (!recipient.includes('@') || !recipient.includes('.com')) return setAlert('error', 'You must enter a valid email address');
    setSending(true);
    if (isRevision) axios.post('/api/event', { ...event, revision: { date: new Date(), complete: false } });
    const res = await axios.post('/api/gapi', { action: 'send-staff-request', recipient, agency, event, request, isRevision });
    const response = res.data;
    console.log(response);
    setSending(false);
    if (!request.error) {
      setAlert('success', `Successfully emailed the staff request to: ${recipient}`);
    } else {
      setAlert('error', `Error! unable to send the staff request to: ${recipient}. Message: ${request.error}`);
    }
  };

  const handleRecipientChange = e => setRecipient(e.target.value);

  const toggleRevision = () => setIsRevision(!isRevision);

  console.log({ isRevision });

  return (
    <li className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'>
      <div className='w-0 flex-1 flex items-center'>
        <PaperClipIcon className='flex-shrink-0 h-5 w-5 text-gray-400' aria-hidden='true' />
        <span className='ml-2 flex-1 w-0 truncate'>
          <div>{request.agency}</div>
          <div class='text-xs text-gray-500'>{moment(request.date).format('MMMM D, YYYY [at] h:mm a')}</div>
        </span>
      </div>
      <div class='flex items-center mr-4'>
        <p class='font-medium mr-2'>Send as revision?</p>
        <input type='checkbox' value={isRevision} onChange={toggleRevision} />
      </div>
      <div style={{ width: 400 }}>
        <div class='rounded-md shadow-sm w-[400px]'>
          <input placeholder='Enter an email to send the request to...' id='recipient' onChange={handleRecipientChange} value={recipient} class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5' />
        </div>
      </div>
      <div className='ml-4 flex space-x-4 flex-shrink-0'>
        <SecondaryButton loading={sending} onClick={sendEmail}>
          Send
        </SecondaryButton>
        <PrimaryButtonLink href={request.pdf}>Download</PrimaryButtonLink>
      </div>
    </li>
  );
};

const mapStateToProps = state => ({
  agencies: state.agency.agencies,
});

export default connect(mapStateToProps, { setAlert })(FileListItem);
