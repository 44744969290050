import React, { useEffect } from 'react';
import WorkerRow from './WorkerRow';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../../actions/user';
import Spinner from '../layout/Spinner';
import Container from '../layout/Container';

const WorkerTable = ({ users, fetchUsers }) => {
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Container variant='blank'>
      {!users.length && <Spinner />}
      <div class='flex flex-col my-10 px-6 sm:px-0'>
        <Link
          to='/workers/new'
          class='inline-flex w-60 shadow mb-2 items-center justify-center px-8 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer'
        >
          + Add new worker
        </Link>
        <div class='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div class='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            <table class='min-w-full'>
              <thead>
                <tr>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Name</th>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Email</th>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Phone</th>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Status</th>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th>
                  {/* <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th> */}
                </tr>
              </thead>
              <tbody class='bg-white'>
                {users.map((worker) => (
                  <WorkerRow key={worker._id} {...worker} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  users: state.user.users,
});

export default connect(mapStateToProps, { fetchUsers })(WorkerTable);
