import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, fetchUsersByPosition } from '../../actions/user';
import GenericSelect from './GenericSelect';
import Select from 'react-select';

const WorkerSelect = ({ id, positionId, value, onChange, options, usersByPosition, fetchUsers, fetchUsersByPosition }) => {
  
  useEffect(() => {
    if(positionId && !usersByPosition[positionId]) fetchUsersByPosition(positionId)
  }, [positionId, fetchUsersByPosition]);

    const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: 'none',
      boxShadow: isFocused ? '0 0 0 3px rgba(164, 202, 254, 0.45)' : 'none',
      borderColor: isFocused ? '#a4cafe' : styles.borderColor,
      ':hover': {
        borderColor: isFocused ? '#a4cafe' : '#d2d6dc',
      },
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'lightgray',
        color: 'black',
      },
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    singleValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    multiValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    option: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  };

  const handleChange = (e) => {
    console.log(e);
    onChange({
      target: { id, value: e },
    });
  };

    const valueObj = options.find((option) => option.value === value._id);

    return <Select value={valueObj} onChange={handleChange} options={options} styles={styles} isClearable={true} />;
};

const mapStateToProps = (state, ownProps) => ({
  options: state.user.usersByPosition[ownProps.positionId] || [],
  usersByPosition: state.user.usersByPosition
});

export default connect(mapStateToProps, { fetchUsers, fetchUsersByPosition })(WorkerSelect);
