import React from 'react';
import PrimaryButtonLink from '../../layout/Buttons/PrimaryButtonLink';

const Tripleseat = ({ event }) => {
  return (
    <div>
      <div className='mt-10 flex space-x-4 w-1/2'>
        <PrimaryButtonLink href={event.proposal_url}>Proposal</PrimaryButtonLink>
        <PrimaryButtonLink href={`https://encorecatering.tripleseat.com/events/${event.event_id}`}>View Tripleseat Event</PrimaryButtonLink>
        <PrimaryButtonLink href={`https://encorecatering.tripleseat.com/bookings/${event.booking_id}`}>View Tripleseat Booking</PrimaryButtonLink>
      </div>
    </div>
  );
};

export default Tripleseat;
