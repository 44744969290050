import axios from './axios'
import { SET_SHIFT, SET_SHIFTS, UPSERT_SHIFT, UPSERT_SHIFTS, RESET_SHIFT } from './types'
import { setAlert } from './alert'

export const fetchShifts = filter => async dispatch => {
  try {
    const res = await axios.get('/api/shift', { params: filter })
    dispatch({
      type: SET_SHIFTS,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchShift = filter => async dispatch => {
  try {
    const res = await axios.get('/api/shift', { params: filter })
    dispatch({
      type: SET_SHIFT,
      payload: res.data[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const setState = shift => async dispatch => {
  dispatch({
    type: SET_SHIFT,
    payload: shift
  })
}

export const setShifts = shifts => async dispatch => {
  dispatch({
    type: SET_SHIFTS,
    payload: shifts
  })
}

export const resetShift = () => async dispatch => {
  dispatch({
    type: RESET_SHIFT
  })
}

export const upsertShift = shift => async dispatch => {
  try {
    const res = await axios.post(`/api/shift`, shift)
    console.log(res)
    dispatch({
      type: UPSERT_SHIFT,
      payload: res.data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertShifts = shifts => async dispatch => {
  try {
    let res = await Promise.all(shifts.map(async shift => await axios.post(`/api/shift`, shift)))
    let data = res.map(r => r.data)
    console.log(res)
    dispatch({
      type: UPSERT_SHIFT,
      payload: data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
