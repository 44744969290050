// Root Reducer
import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import position from './position';
import event from './event';
import shift from './shift';
import user from './user';
import agency from './agency';
import log from './log';
import instawork from './instawork';

export default combineReducers({
  alert,
  auth,
  position,
  event,
  shift,
  user,
  agency,
  log,
  instawork,
});
