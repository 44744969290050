import React from 'react'
import moment from 'moment'

const EventHistory = ({ logs, deleteLog }) => {
  logs = logs || []

  const eventLogs = logs.filter(log => log.type === 'event' && log.operation === 'updated')

  const updatedShiftLogs = {}
  const addedRemovedShiftsLogs = {}

  for (const log of logs) {
    if (log.type === 'shift' && log.operation === 'updated') {
      const key = log.formattedDate + log.label + log.originalValue.value + log.newValue.value
      updatedShiftLogs[key] = {
        ...log,
        count: updatedShiftLogs[key] ? updatedShiftLogs[key].count + 1 : 1
      }
    } else if (log.type === 'shift' && log.operation === 'added') {
      const shift = log.newValue.value
      const key = log.formattedDate + shift.position + shift.expected_start_time + shift.expected_end_time
      addedRemovedShiftsLogs[key] = {
        ...log,
        count: addedRemovedShiftsLogs[key] ? addedRemovedShiftsLogs[key].count + 1 : 1,
        shift
      }
    } else if (log.type === 'shift' && log.operation === 'removed') {
      const shift = log.originalValue.value
      const key = log.formattedDate + shift.position + shift.expected_start_time + shift.expected_end_time
      addedRemovedShiftsLogs[key] = {
        ...log,
        count: addedRemovedShiftsLogs[key] ? addedRemovedShiftsLogs[key].count + 1 : 1,
        shift
      }
    }
  }

  const shiftLogs = [...Object.values(updatedShiftLogs), ...Object.values({ ...addedRemovedShiftsLogs })]
  const combinedLogs = [...eventLogs, ...shiftLogs].sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())

  const handleRemove = id => deleteLog(id)

  const dateTimeRef = [
    { label: 'event_date', format: 'MMM D, YYYY' },
    { label: 'time_of_party', format: 'h:mm a' }
  ]

  return (
    <div>
      {!combinedLogs.length && <div class='text-sm text-gray-700'>There have not been any changes to this event</div>}
      {combinedLogs.map(({ _id, operation, field, label, type, originalValue, newValue, formattedDate, user, shift, count }) => {
        console.log({ _id, field })
        if (!label && field) label = field.replace(/_/g, ' ').replace(/\b\S/g, t => t.toUpperCase())

        let oValue = originalValue.value
        let nValue = newValue.value

        const dateTimeObj = dateTimeRef.find(f => f.label === field)
        if (dateTimeObj) {
          oValue = moment(oValue).format(dateTimeObj.format)
          nValue = moment(nValue).format(dateTimeObj.format)
        }

        if (type === 'event') {
          return (
            <div className='ml-4 mt-2 flex justify-between'>
              <div>
                <h3 className='text-sm font-medium leading-6 text-gray-900'>
                  The <b>{label}</b> of the {type} was updated from <span class='font-bold italic'>"{oValue}"</span> to{' '}
                  <span class='font-bold italic'>"{nValue}"</span>
                </h3>
                <p className='text-xs text-gray-500'>
                  <span>
                    By {user.full_name} on {formattedDate}
                  </span>
                </p>
              </div>
              <div class='cursor-pointer text-2xl text-gray-500 hover:text-gray-700' onClick={() => handleRemove(_id)}>
                &times;
              </div>
            </div>
          )
        } else if (type === 'shift' && operation === 'updated') {
          return (
            <div className='ml-4 mt-2 flex justify-between'>
              <div>
                <h3 className='text-sm font-medium leading-6 text-gray-900'>
                  The <b>{label}</b> for {count} shifts was updated from <span class='font-bold italic'>"{oValue}"</span> to{' '}
                  <span class='font-bold italic'>"{nValue}"</span>
                </h3>
                <p className='text-xs text-gray-500'>
                  <span>
                    By {user.full_name} on {formattedDate}
                  </span>
                </p>
              </div>
              <div class='cursor-pointer text-2xl text-gray-500 hover:text-gray-700' onClick={() => handleRemove(_id)}>
                &times;
              </div>
            </div>
          )
        } else {
          return (
            <div className='ml-4 mt-2 flex justify-between'>
              <div>
                <h3 className='text-sm font-medium leading-6 text-gray-900'>
                  <b>{count} shifts</b> for <b>"{shift.position}"</b> were {operation} for{' '}
                  <b>
                    "{shift.expected_start_time} to {shift.expected_end_time}"
                  </b>
                </h3>
                <p className='text-xs text-gray-500'>
                  <span>
                    By {user.full_name} on {formattedDate}
                  </span>
                </p>
              </div>
              <div class='cursor-pointer text-2xl text-gray-500 hover:text-gray-700' onClick={() => handleRemove(_id)}>
                &times;
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default EventHistory
