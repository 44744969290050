import React from 'react';
import ShiftItem from './ShiftItem';

const ShiftGroupBody = ({ visible, groupBy, shifts, setShift, removeShift }) => {
  visible = groupBy === 'none' ? true : visible;

  return (
    <div class={`mt-6 ${groupBy !== 'none' ? 'ml-16' : 'ml-2'}`}>
      {visible &&
        shifts.map(function (shift, index) {
          return <ShiftItem index={index} shift={shift} groupBy={groupBy} setShift={setShift} removeShift={removeShift} />;
        })}
    </div>
  );
};

export default ShiftGroupBody;
