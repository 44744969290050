import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Row = ({ _id, name, active, handleDelete }) => {
  const navigate = useNavigate()
  return (
    <tr>
      <td
        onClick={() => navigate(`/agencies/${_id}`)}
        class='whitespace-no-wrap cursor-pointer border-b border-gray-200 px-6 py-4 text-sm font-medium leading-5 text-indigo-600'
      >
        {name}
      </td>
      {/* <td class='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900 truncate max-w-md'>{active ? 'Active' : 'Inactive'}</td> */}
      <td class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-right text-sm font-medium leading-5'>
        <Link to={`/agencies/${_id}`} class='text-indigo-600 hover:text-indigo-900'>
          Edit
        </Link>
      </td>
      {/* <td class='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <span onClick={() => handleDelete(_id)} class='cursor-pointer text-red-600 hover:text-red-900'>
          Remove
        </span>
      </td> */}
    </tr>
  )
}

export default Row
