import { SET_LOG, SET_LOGS, UPSERT_LOG, RESET_LOG, DELETE_LOG } from '../actions/types';

const defaultState = {
  log: {},
  logs: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_LOG:
    case UPSERT_LOG:
      return {
        ...state,
        log: {
          ...payload,
        },
      };
    case DELETE_LOG:
      return {
        ...state,
        log: { ...defaultState.log },
        logs: [...state.logs.filter((log) => log._id !== payload.id)],
      };
    case RESET_LOG:
      return {
        ...state,
        log: { ...defaultState.log },
      };
    case SET_LOGS:
      return {
        ...state,
        logs: [...payload],
      };
    default:
      return state;
  }
};
