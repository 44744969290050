import React from 'react';

const Container = ({ children, style, variant }) => {
  return (
    <div style={style} class={`${variant === 'blank' ? '' : 'bg-white shadow-md'} mt-20 lg:mt-32 mb-6 px-4 py-5 sm:rounded-lg sm:p-6`}>
      {children}
    </div>
  );
};

export default Container;
