import React from 'react';
import { Link } from 'react-router-dom';

const WorkerRow = ({ _id, first_name, last_name, email, phone, status }) => {
  return (
    <tr>
      <td class='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900'>
        {first_name} {last_name}
      </td>
      <td class='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-900'>{email}</td>
      <td class='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-900'>{phone}</td>
      <td class='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-900'>
        <span class={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>{status}</span>
      </td>
      <td class='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <Link to={`/workers/${_id}`} class='text-indigo-600 hover:text-indigo-900'>
          Edit
        </Link>
      </td>
      {/* <td class='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <Link to={`/workers/${_id}`} class='text-indigo-600 hover:text-indigo-900'>
          Delete
        </Link>
      </td> */}
    </tr>
  );
};

export default WorkerRow;
