import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const UserSettings = ({ user, logout }) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => (user.company ? setOpen(!open) : null);

  return (
    <div class='ml-3 relative'>
      <div>
        <button
          class='flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out'
          id='user-menu'
          aria-label='User menu'
          aria-haspopup='true'
          onClick={toggleMenu}
        >
          <img class='h-8 w-8 rounded-full border border-gray-300' src='https://icon-library.com/images/generic-profile-icon/generic-profile-icon-23.jpg' alt='' />
        </button>
      </div>

      {open && (
        <div onMouseLeave={toggleMenu} onClick={toggleMenu} class='z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg'>
          <div class='py-1 rounded-md bg-white shadow-xs'>
            <Link to='/home' class='block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'>
              Your Profile
            </Link>
            <Link to='/support' class='block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'>
              Support
            </Link>
            <button
              onClick={logout}
              class='block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSettings;
