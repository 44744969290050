import React from 'react';
import PositionSelect from '../layout/PositionSelect';

const Positions = ({ positions, updatePosition, removePosition }) => {
  function renderPosition(workerPosition) {
    const handleChange = (e) => updatePosition(e, workerPosition);

    console.log(workerPosition);

    return (
      <>
        <div class='sm:col-span-2'>
          <div class='mt-1 rounded-md shadow-sm'>
            <PositionSelect id='position' onChange={handleChange} value={workerPosition.position} />
          </div>
        </div>

        <div class='sm:col-span-2'>
          <div class='mt-1 rounded-md shadow-sm'>
            <input id='rate' onChange={handleChange} value={workerPosition.rate || ''} class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5' />
          </div>
        </div>
        <div class='sm:col-span-2'>
          <div class='cursor-pointer text-indigo-700 text-xs mt-1 h-full flex items-center justify-left'>
            <div class='hover:opacity-75' onClick={() => removePosition(workerPosition._id)}>
              Remove
            </div>
          </div>
        </div>
      </>
    );
  }

  return positions.map((workerPosition) => renderPosition(workerPosition));
};

export default Positions;
