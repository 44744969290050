import React from 'react';
import { connect } from 'react-redux';
import Input from '../../layout/Input';
import WorkerSelect from '../../layout/WorkerSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { XIcon } from '@heroicons/react/outline';
import AgencySelect from '../../layout/AgencySelect';
import PositionSelect from '../../layout/PositionSelect';
import moment from 'moment';
import { setAlert } from '../../../actions/alert';

const ShiftItem = ({ index, shift, groupBy, setShift, removeShift, user, event, setAlert }) => {
  const handleChange = (e) => {
    setShift({
      ...shift,
      [e.target.id]: moment.isDate(e.target.value) ? e.target.value.toISOString() : e.target.value,
    });
  };

  const handleWorkerChange = (e) => {
    const user = e.target.value;
    if (user && shift.position) {
      shift.rate = user.positions.find((userPosition) => userPosition.position._id === shift.position._id).rate;
    }
    setShift({
      ...shift,
      rate: shift.rate,
    });
  };

  const handleShiftTimeChange = (date, id) => {
    // 'expected_start_time'

    if (id === 'expected_end_time' && !shift.expected_start_time) {
      return setAlert('error', 'You must first select a start time for the shift!');
    }

    const eventDate = moment(event.event_date);
    let revisedDate = moment(date).set({
      year: eventDate.get('year'),
      month: eventDate.get('month'),
      date: eventDate.get('date'),
    });

    // need to ensure that the expected end time is greater than the expected start time
    if (id === 'expected_end_time' && moment(shift.expected_start_time) > revisedDate) {
      revisedDate = revisedDate.add(1, 'days');
    }

    console.log(revisedDate);

    handleChange({ target: { id, value: revisedDate.toDate() } });
  };

  return (
    <div class='grid grid-cols-1 row-gap-6 col-gap-2 sm:grid-cols-11'>
      {groupBy !== 'position' && (
        <div class='sm:col-span-2'>
          {index === 0 && (
            <label for='selection_deadline' class='block text-xs font-medium leading-5 text-gray-700'>
              Position
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <PositionSelect id='position' value={shift.position || {}} onChange={handleChange} isClearable={false} />
          </div>
        </div>
      )}

      {groupBy !== 'agency' && (
        <div class='sm:col-span-2'>
          {index === 0 && (
            <label for='agency' class='block text-xs font-medium leading-5 text-gray-700'>
              Agency
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <AgencySelect id='agency' value={shift.agency || {}} onChange={handleChange} isClearable={false} />
          </div>
        </div>
      )}
      {/* 
      {user.account_type === 'Admin' && (
        <div class='sm:col-span-2'>
          {index === 0 && (
            <label for='selection_deadline' class='block text-xs font-medium leading-5 text-gray-700'>
              Worker
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <WorkerSelect id='user' value={shift.user || {}} positionId={shift.position && shift.position._id} onChange={handleWorkerChange} />
          </div>
        </div>
      )} */}

      <div class='sm:col-span-1 min-w-min-content'>
        {index === 0 && (
          <label for='selection_deadline' class='block text-xs font-medium leading-5 text-gray-700'>
            Start Time
          </label>
        )}
        <div class='mt-1 rounded-md '>
          <DatePicker
            id='expected_start_time'
            className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
            selected={shift.expected_start_time ? new Date(shift.expected_start_time) : ''}
            onChange={(date) => handleShiftTimeChange(date, 'expected_start_time')}
            dateFormat='h:mm aa'
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
          />
        </div>
      </div>

      <div class='sm:col-span-1 min-w-min-content'>
        {index === 0 && (
          <label for='selection_deadline' class='block text-xs font-medium leading-5 text-gray-700'>
            End Time
          </label>
        )}
        <div class='mt-1 rounded-md '>
          <DatePicker
            id='expected_end_time'
            className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
            selected={shift.expected_end_time ? new Date(shift.expected_end_time) : ''}
            onChange={(date) => handleShiftTimeChange(date, 'expected_end_time')}
            dateFormat='h:mm aa'
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
          />
        </div>
      </div>

      {user.account_type === 'Admin' && (
        <>
          <div class='sm:col-span-1'>
            {index === 0 && (
              <label for='rate' class='block text-xs font-medium leading-5 text-gray-700'>
                Hourly Rate
              </label>
            )}
            <Input type='number' handleChange={handleChange} id='rate' value={shift.rate} />
          </div>
          {/* <div class='sm:col-span-1'>
            {index === 0 && (
              <label for='gratuity' class='block text-xs font-medium leading-5 text-gray-700'>
                Gratuity
              </label>
            )}
            <Input type='number' handleChange={handleChange} id='gratuity' value={shift.gratuity} />
          </div> */}
          <div class='sm:col-span-1'>
            {index === 0 && (
              <label type='number' for='additional_costs' class='block text-xs font-medium leading-5 text-gray-700'>
                Flat Fees
              </label>
            )}
            <Input type='number' handleChange={handleChange} id='additional_costs' value={shift.additional_costs} />
          </div>
        </>
      )}

      <div class='sm:col-span-1'>
        <div class='cursor-pointer text-gray-500 text-xs mt-1 h-full flex items-center justify-left'>
          <div class={`hover:opacity-75 ${index === 0 ? 'mt-5' : ''}`} onClick={() => removeShift(shift._id)}>
            <XIcon class='h-6 w-6' />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  event: state.event.event,
});

export default connect(mapStateToProps, { setAlert })(ShiftItem);
