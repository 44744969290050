import React, { useEffect } from 'react'
import * as msal from '@azure/msal-browser'
import { connect } from 'react-redux'

import MicrosoftLoginButton from './MicrosoftLoginButton'
import { loginWithMSAL } from '../../../actions/auth'
import { setAlert } from '../../../actions/alert'

const MicrosoftLogin = ({ setAlert, loginWithMSAL }) => {
  const msalConfig = {
    auth: {
      clientId: '29b80ffd-27b4-499c-bb0c-e57d7a3887fb',
      authority: 'https://login.microsoftonline.com/c721d5ac-60bc-45d4-a29c-e89ff5289d36',
      redirectUri: '/login'
    }
  }

  const msalInstance = new msal.PublicClientApplication(msalConfig)

  const login = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup({ scopes: ['user.read'] })
      console.log('loginResponse: ', loginResponse)
      const username = loginResponse.account.username
      const res = await loginWithMSAL(loginResponse.accessToken)
      console.log('res: ', res)
      if (res.error) setAlert('error', `Unable to login as "${username}". ${res.error}`)
    } catch (err) {
      setAlert('error', 'An error occured when attempting to sign in. Please try again.')
    }
  }

  return <MicrosoftLoginButton onClick={login} />
}

export default connect(null, { setAlert, loginWithMSAL })(MicrosoftLogin)
