import { SET_AGENCY, SET_AGENCIES, UPSERT_AGENCY, RESET_AGENCY, DELETE_AGENCY } from '../actions/types';

const defaultState = {
  agency: {},
  agencies: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_AGENCY:
    case UPSERT_AGENCY:
      return {
        ...state,
        agency: {
          ...payload,
        },
      };
    case DELETE_AGENCY:
      return {
        ...state,
        agency: { ...defaultState.agency },
        agencies: [...state.agencies.filter((agency) => agency._id !== payload.id)],
      };
    case RESET_AGENCY:
      return {
        ...state,
        agency: { ...defaultState.agency },
      };
    case SET_AGENCIES:
      return {
        ...state,
        agencies: [...payload],
      };
    default:
      return state;
  }
};
