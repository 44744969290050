import { SET_ALERT, SET_ALERT_GENERATOR } from './types';

// defines the setAlert action
export const setAlert = (type, msg, timeout = 5000) => (dispatch, getState) => {
  // const id = uuid.v4();

  const alertGenerator = getState().alert;
  const alert = alertGenerator.show(msg, { type, timeout });

  dispatch({
    type: SET_ALERT,
    payload: alert,
  });
};

export const setAlertGenerator = (alertGenerator) => (dispatch) => {
  dispatch({
    type: SET_ALERT_GENERATOR,
    payload: alertGenerator,
  });
};
