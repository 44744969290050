import React from 'react';

const Spinner = ({ style }) => {
  return (
    <div style={style} class='text-indigo-500 spinner-container'>
      <div class='spinner'>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
