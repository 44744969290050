import axios from './axios'
import {
  SET_EVENT,
  FETCH_EVENT,
  SET_EVENTS,
  UPSERT_EVENT,
  RESET_EVENT,
  DELETE_EVENT,
  ADD_EVENT_SHIFT,
  SET_EVENT_SHIFT,
  SET_EVENT_SHIFTS,
  REMOVE_EVENT_SHIFT,
  DELETE_SHIFT
} from './types'
import { setAlert } from './alert'
import * as tripleseat from './tripleseat'
import { fetchShifts } from './shift'
import moment from 'moment'
import { transform, isEqual, isArray, isObject, initial } from 'lodash'
import { difference } from '../utils/functions'

import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

export const fetchEvents = (filter, limit, page) => async dispatch => {
  try {
    const res = await axios.get('/api/event', { params: { filter, limit, page } })
    dispatch({
      type: SET_EVENTS,
      payload: res.data.events
    })
    return res.data
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchEvent = (filter, limit, page) => async dispatch => {
  try {
    const res = await axios.get('/api/event', { params: { filter, limit, page } })
    dispatch({
      type: FETCH_EVENT,
      payload: res.data.events[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchOrCreateEvents = bookingId => async dispatch => {
  let events, res

  // Search for existing events related to the booking id provided
  const filter = { booking_id: bookingId }
  res = await axios.get('/api/event', { params: { filter } })
  events = res.data.events

  // If existing events do not exist, pull the tripleseat data and create the events
  if (!events.length) events = await createEvents(bookingId)

  dispatch({
    type: SET_EVENTS,
    payload: events
  })
  dispatch({
    type: SET_EVENT,
    payload: events[0]
  })
  dispatch(fetchShifts({ event: events[0]._id }))
}

export const createEvents = async bookingId => {
  const booking = await tripleseat.getRecord('bookings', bookingId)

  const excludedEventTypes = ['Pick Up', 'Tasting']
  const dates = []

  let events = await booking.event_ids.reduce(async (events, eventId) => {
    const event = await tripleseat.getRecord('events', eventId)
    if (excludedEventTypes.includes(event.event_type) || dates.includes(event.start_date)) return events
    const coordinator = await tripleseat.getCoordinator(event)

    return [
      ...events,
      {
        event_id: eventId,
        booking_id: bookingId,
        booking_name: booking.name,
        name: event.name,
        company: event.location.name,
        event_date: event.start_date,
        client_name: booking.account.name,
        venue: event['Venue Name'],
        address: event['Venue Location'],
        event_type: event.event_type,
        time_of_party: event.event_start_time,
        guest_count: event.guest_count,
        contact_phone: event['Venue Phone Number'],
        encore_contact: `${coordinator.first_name} ${coordinator.last_name}`,
        event_start: event.event_start_time,
        event_end: event.event_end_time,
        setup_time: event.setup_time,
        teardown_time: event.teardown_time,
        event_start_time_with_setup_time: event.event_start_time_with_setup_time,
        event_end_time_with_teardown_time: event.event_end_time_with_teardown_time
      }
    ]
  }, [])

  // Commit the events to the database
  const res = await Promise.all(
    events.map(async event => {
      const res = await axios.post(`/api/event`, event)
      return res.data
    })
  )
  return res
}

export const getProposalItems = event => async dispatch => {
  const tEventId = event.event_id
  const res = await axios.post('/api/gapi', { action: 'get-staff-shifts', eventId: tEventId })
  const proposalItems = res.data
  if (!proposalItems.length) return []
  dispatch(
    upsertEvent({
      _id: event._id,
      proposal: proposalItems
    })
  )
}

function round(num, decimals) {
  if (!decimals) decimals = 2
  return +(Math.round(num + `e+${decimals}`) + `e-${decimals}`)
}

export const calculateProfit = event => async dispatch => {
  let res

  // Get staffing line items from proposal to calculate revenue

  const tEventId = event.event_id
  res = await axios.post('/api/gapi', { action: 'get-staff-shifts', eventId: tEventId })
  const proposalItems = res.data
  console.log({ proposalItems })

  // Get instawork shifts to calculate expenses

  res = await axios.post('/api/instawork', { action: 'get-consolidated-shifts', eventId: event.iw_event_id })
  const instaworkShifts = res.data

  // Check for shifts from Yane staffing

  const yaneShifts = event.shifts.filter(shift => shift.agency?.name === 'Yane Staffing' && shift.status === 'Completed')
  const consolidatedYaneShifts = Object.values(
    yaneShifts.reduce((obj, shift) => {
      const position = shift.position?.name || ''
      const key = `${position}-${shift.rate}`
      const hours = round((moment(shift.expected_end_time) - moment(shift.expected_start_time)) / 1000 / 60 / 60, 2)
      const current = obj[key] || { quantity: 0, hours: 0, total: 0 }
      const total = round(shift.rate * hours, 2)
      obj[key] = {
        source: 'Yane Staffing',
        position: position,
        quantity: current.quantity + 1,
        hours: current.hours + hours,
        rate: shift.cost,
        total: current.total + total,
        status: shift.completed ? 'Completed' : 'Pending'
      }
      return obj
    }, {})
  )

  const costs = [...instaworkShifts, ...consolidatedYaneShifts]

  console.log(costs)

  dispatch(
    upsertEvent(
      {
        ...event,
        proposal: proposalItems,
        costs: costs
      },
      false
    )
  )
}

export const fetchOrCreateEvent = eventId => async dispatch => {
  let event = {
    _id: new ObjectId().toString(),
    shifts: [],
    requests: [],
    logs: [],
    isNew: true
  }

  console.log(event)

  // look for existing event

  const filter = { event_id: eventId }

  const res = await axios.get('/api/event', { params: { filter } })
  if (res.data.events.length) {
    event = {
      ...event,
      ...res.data.events[0],
      isNew: false
    }
  }

  console.log(event)

  // otherwise create a new event with the data from tripleseat

  const record = await tripleseat.getRecord('events', eventId)
  console.log(record)
  const coordinator = await tripleseat.getCoordinator(record)

  console.log('getting shifts from tripleseat...')
  const proposalItemsResponse = await axios.post('/api/gapi', { action: 'get-staff-shifts', eventId: eventId })
  const proposalItems = proposalItemsResponse.data
  console.log({ proposalItems })

  const shifts = event.shifts.length ? event.shifts : await getShiftsFromEvent(proposalItems, event, record)

  event = {
    ...event,
    event_id: eventId,
    booking_id: record.booking.id,
    booking_name: record.booking.name,
    name: record.name,
    company: record.location.name,
    event_date: record.event_start_iso8601,
    client_name: record.booking.account.name,
    venue: record['Venue Name'],
    address: record['Venue Location'],
    event_type: record.event_type,
    time_of_party: record.event_start_utc,
    guest_count: record.guest_count,
    contact_phone: record['Venue Phone Number'],
    encore_contact: `${coordinator.first_name} ${coordinator.last_name}`,
    event_start: record.event_start_time,
    event_end: record.event_end_time,
    setup_time: record.setup_time,
    teardown_time: record.teardown_time,
    event_start_time_with_setup_time: record.event_start_with_setup_iso8601,
    event_end_time_with_teardown_time: record.event_end_with_teardown_iso8601,
    shifts: shifts,
    proposal: proposalItems,
    logs: []
  }

  console.log(event)

  dispatch({
    type: FETCH_EVENT,
    payload: event
  })
}

const getShiftsFromEvent = async (proposalItems, event, record) => {
  console.log('creating shift objects from the proposal items...')
  const positions = (await axios.get('/api/position')).data
  console.log({ positions })
  const shifts = proposalItems.reduce((arr, { position, quantity, hours, rate, total }) => {
    console.log({ position, quantity, hours, rate, total })
    const matchingPosition = positions.find(pos => pos.name.toLowerCase().trim() == position.toLowerCase().trim())
    console.log({ matchingPosition })
    const shift = {
      event: event._id,
      agency: { _id: '61338f6d23c6555b0ca95549', name: 'Instawork' },
      position: positions.find(pos => pos.name.toLowerCase().trim() == position.toLowerCase().trim()) || {},
      rate: rate,
      expected_start_time: record.event_start_with_setup_iso8601,
      expected_end_time: moment(record.event_start_with_setup_iso8601).add(hours, 'hours') // @CHANGE
    }
    const newShifts = Array.from({ length: quantity })
      .fill(shift)
      .map(shift => ({ ...shift, _id: new ObjectId().toString() }))
    return [...arr, ...newShifts]
  }, [])

  console.log(shifts)

  return shifts
}

export const setState = event => async dispatch => {
  dispatch({
    type: SET_EVENT,
    payload: event
  })
}

export const addShift = shift => async dispatch => {
  dispatch({
    type: ADD_EVENT_SHIFT,
    payload: shift
  })
}

export const setShift = shift => async dispatch => {
  dispatch({
    type: SET_EVENT_SHIFT,
    payload: shift
  })
}
export const setShifts = shifts => async dispatch => {
  dispatch({
    type: SET_EVENT_SHIFTS,
    payload: shifts
  })
}

export const removeShift = shiftId => async dispatch => {
  dispatch({
    type: REMOVE_EVENT_SHIFT,
    payload: shiftId
  })
  await axios.delete(`/api/shift/${shiftId}`)
  dispatch({
    type: DELETE_SHIFT,
    payload: { id: shiftId }
  })
}

export const resetEvent = () => async dispatch => {
  dispatch({
    type: RESET_EVENT
  })
}

export const deleteEvent = id => async dispatch => {
  try {
    await axios.delete(`/api/event/${id}`)
    dispatch({
      type: DELETE_EVENT,
      payload: { id }
    })
    dispatch(setAlert('success', 'Successfully deleted the event'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertEvent = (event, alert) => async (dispatch, getState) => {
  console.log('attempting to upsert event...')

  alert = alert === false ? false : true

  const state = getState()
  const initialEventState = { ...state.event.initialState }
  const user = state.auth.user

  try {
    // update the shifts
    await Promise.all(
      event.shifts.map(async shift => {
        return axios.post(`/api/shift`, shift)
      })
    )

    const res = await axios.post(`/api/event`, { ...event, logs: null })
    console.log(res)

    // log the changes made to the event if the event is not a new event

    const diffs = getDiffs(initialEventState, event, user)
    console.log(diffs)

    await Promise.all(
      diffs.map(async diff => {
        return axios.post(`/api/log`, diff)
      })
    )

    console.log('upserting the event...')

    dispatch({
      type: UPSERT_EVENT,
      payload: {
        ...event,
        logs: [...event.logs, ...diffs],
        isNew: false
      }
    })
    if (alert) dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

const getDiffs = (initialEventState, currentEventState, user) => {
  // do not create logs for test events
  if (currentEventState.name.includes('Test Event')) return []

  // do not create logs for specific field updates
  // if (!currentEventState.shifts) return [];

  if (currentEventState.isNew || !initialEventState._id) {
    return [
      {
        operation: 'added',
        type: 'event',
        id: currentEventState._id,
        event: currentEventState._id,
        field: null,
        originalValue: {
          type: 'object',
          value: {}
        },
        newValue: {
          type: 'object',
          value: currentEventState
        },
        notification: {
          status: 'pending'
        },
        date: new Date(),
        formattedDate: moment().format('MMM Do YYYY, h:mm a'),
        user: { _id: user._id, full_name: user.full_name }
      }
    ]
  }

  const diffs = []

  // check for event differences

  const EventWatch = [
    'booking_name',
    'name',
    'company',
    'coordinator',
    'event_date',
    'client_name',
    'venue',
    'address',
    'intersection',
    'event_type',
    'type_of_service',
    'time_of_party',
    'guest_count',
    'contact_name',
    'contact_phone',
    'encore_contact',
    'event_start',
    'event_end',
    'setup_time',
    'teardown_time',
    'scheduling_notes',
    'staff_notes'
  ]

  for (const [key, value] of Object.entries(currentEventState)) {
    if (EventWatch.includes(key) && !isEqual(value || '', initialEventState[key] || '')) {
      diffs.push({
        operation: 'updated',
        type: 'event',
        id: initialEventState._id,
        event: initialEventState._id,
        field: key,
        originalValue: {
          type: 'string',
          value: initialEventState[key]
        },
        newValue: {
          type: 'string',
          value: value
        },
        notification: {
          status: 'pending'
        },
        date: new Date(),
        formattedDate: moment().format('MMM Do YYYY, h:mm a'),
        user: { _id: user._id, full_name: user.full_name }
      })
    }
  }

  // check for shift differences

  console.log('Checking shifts....')

  const ShiftWatch = ['expected_start_time', 'expected_end_time', 'position']

  currentEventState.shifts.map((currentShift, i) => {
    // check if shift existed in initial state - if not it was added
    const initialShiftState = initialEventState.shifts.find(shift => shift._id === currentShift._id)
    if (!initialShiftState) {
      diffs.push({
        operation: 'added',
        type: 'shift',
        id: currentShift._id,
        event: isObject(currentShift.event) ? currentShift.event._id : currentShift.event,
        originalValue: {
          type: 'object',
          value: {}
        },
        newValue: {
          type: 'object',
          value: {
            ...currentShift,
            position: currentShift.position?.label,
            agency: currentShift.agency?.label,
            expected_start_time: currentShift.expected_start_time ? moment(currentShift.expected_start_time).format('h:mm a') : '',
            expected_end_time: currentShift.expected_end_time ? moment(currentShift.expected_end_time).format('h:mm a') : ''
          }
        },
        notification: {
          status: 'pending'
        },
        date: new Date(),
        formattedDate: moment().format('MMM Do YYYY, h:mm a'),
        user: { _id: user._id, full_name: user.full_name }
      })
    } else {
      for (const [key, value] of Object.entries(currentShift)) {
        if (ShiftWatch.includes(key) && !isEqual(value || '', initialShiftState[key] || '')) {
          console.log(initialShiftState[key])
          console.log(value)
          diffs.push({
            operation: 'updated',
            type: 'shift',
            id: currentShift._id,
            event: isObject(currentShift.event) ? currentShift.event._id : currentShift.event,
            field: key,
            originalValue: {
              type: 'string',
              value: isObject(initialShiftState[key])
                ? initialShiftState[key].label
                : moment(initialShiftState[key]).isValid()
                ? moment(initialShiftState[key]).format('h:mm a')
                : initialShiftState[key]
            },
            newValue: {
              type: 'string',
              value: isObject(value) ? value.label : moment(value).isValid() ? moment(value).format('h:mm a') : value
            },
            notification: {
              status: 'pending'
            },
            date: new Date(),
            formattedDate: moment().format('MMM Do YYYY, h:mm a'),
            user: { _id: user._id, full_name: user.full_name }
          })
        }
      }
    }
  })

  // check for any shifts that were removed
  initialEventState.shifts.map(shift => {
    const match = currentEventState.shifts.find(s => s._id === shift._id)
    if (!match) {
      diffs.push({
        operation: 'removed',
        type: 'shift',
        id: shift._id,
        event: isObject(shift.event) ? shift.event._id : shift.event,
        originalValue: {
          type: 'object',
          value: {
            ...shift,
            position: shift.position?.name,
            agency: shift.agency?.name,
            expected_start_time: shift.expected_start_time ? moment(shift.expected_start_time).format('h:mm a') : '',
            expected_end_time: shift.expected_end_time ? moment(shift.expected_end_time).format('h:mm a') : ''
          }
        },
        newValue: {
          type: 'object',
          value: {}
        },
        notification: {
          status: 'pending'
        },
        date: new Date(),
        formattedDate: moment().format('MMM Do YYYY, h:mm a'),
        user: { _id: user._id, full_name: user.full_name }
      })
    }
  })

  return diffs
}
