import { XIcon } from '@heroicons/react/solid';
import React from 'react';

const Task = ({ task, deleteTask, updateTask }) => {
  console.log(task);

  const { _id, date, complete, description, user } = task;

  function toggleComplete() {
    updateTask({ ...task, complete: !complete });
  }

  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  return (
    <div className={classNames(complete && 'line-through opacity-75', 'flex justify-between py-2')}>
      <div className='flex items-center'>
        <input type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6' checked={complete} onChange={toggleComplete} style={{ accentColor: '#5850ec' }} />
        <div className='ml-2 leading-tight'>
          <div className='text-indigo-700 font-semibold'>{description}</div>
          <div className='text-xs text-gray-500 italic'>
            {user} {new Date(date).toLocaleString()}
          </div>
        </div>
      </div>
      <div onClick={() => deleteTask(_id)}>
        <XIcon class='h-4 w-4' />
      </div>
    </div>
  );
};

export default Task;
