import React, { useState, useEffect } from 'react'
import { Link, Navigate, useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../../actions/auth'
import MicrosoftLogin from './MicrosoftLogin/MicrosoftLogin'

// Pass this component the actions it will use and pull out the props it will need
const Login = ({ login, isAuthenticated, user }) => {
  const [formData, setFormData] = useState({})

  const [error, setError] = useState('')

  const params = useParams()

  useEffect(() => {
    const { email, password } = params
    setFormData({ email, password })
  }, [params])

  // Destructure and pull out the required constants out of the state
  const { email, password } = formData

  // This will update the state with the changes as they occur
  const onChange = e => setFormData({ ...formData, [e.target.id]: e.target.value })

  // Trigger the login action on submit
  const onSubmit = async e => {
    e.preventDefault()
    setError('')
    const res = await login(email, password)
    if (res.msg) setError(res.msg)
  }

  const location = useLocation()

  // // Navigate if authenticated
  if (isAuthenticated && user.first_name) {
    return <Navigate to='/events' />
  }

  return (
    <div class='relative flex flex-col sm:px-6 md:py-12 lg:px-8'>
      <div class='z-10 mt-24 sm:mx-auto sm:mt-40 sm:w-full sm:max-w-md'>
        <div class='bg-white px-4 py-8 shadow-lg sm:rounded-lg sm:px-10'>
          <h2 class='text-cool-gray-800 mb-12 mt-8 text-center text-3xl font-extrabold leading-9'>Sign in to your account</h2>
          <form onSubmit={onSubmit}>
            <div>
              <label for='email' class='block text-sm font-medium leading-5 text-gray-700'>
                Email address
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='email'
                  type='email'
                  required
                  value={email}
                  onChange={onChange}
                  class='focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            <div class='mt-6'>
              <label for='password' class='block text-sm font-medium leading-5 text-gray-700'>
                Password
              </label>
              <div class='mt-1 rounded-md shadow-sm'>
                <input
                  id='password'
                  type='password'
                  required
                  value={password}
                  onChange={onChange}
                  class='focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5'
                />
              </div>
            </div>

            {/* <div class='mt-6 flex items-center justify-between'>
              <div class='flex items-center'>
                <input id='remember_me' type='checkbox' class='form-checkbox h-4 w-4 text-indigo-700 transition duration-150 ease-in-out' />
                <label for='remember_me' class='ml-2 block text-sm leading-5 text-gray-900'>
                  Remember me
                </label>
              </div>

              <div class='text-sm leading-5'>
                <Link to='/forgot-password' class='font-medium text-indigo-700 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150'>
                  Forgot your password?
                </Link>
              </div>
            </div> */}

            <div class='mt-6'>
              <span class='block w-full rounded-md shadow-sm'>
                <button
                  type='submit'
                  class='focus:shadow-outline-blue flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:opacity-75 focus:border-blue-700 focus:outline-none'
                >
                  Sign in
                </button>
              </span>
              <div className='w-full'>
                <MicrosoftLogin />
              </div>
            </div>
            {error && <p class='mt-6 text-center text-sm text-red-600'>{error}</p>}
          </form>
          <p class='-mb-2 mt-6 block text-center text-sm font-medium leading-5 text-gray-700'></p>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

// This pulls the auth object from the App-level state into the component state
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
})

export default connect(mapStateToProps, { login })(Login)
