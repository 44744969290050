import React, { useState } from 'react';
import SecondaryButton from '../../layout/Buttons/SecondaryButton';
import AgencySelect from '../../layout/AgencySelect';
import axios from '../../../actions/axios';
import FileListItem from './FileListItem';

const FileList = ({ event, upsertEvent }) => {
  const [loading, setLoading] = useState(false);
  const [agency, setAgency] = useState({});

  const generateRequest = async agency => {
    setLoading(true);
    const res = await axios.post('/api/gapi', { action: 'generate-staff-request', agency, event });
    const request = res.data;
    console.log(request);
    if (!request.error) {
      upsertEvent({
        ...event,
        requests: [request, ...event.requests],
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <div class='flex my-6'>
        <AgencySelect id='agency' value={agency} isClearable={false} onChange={e => setAgency(e.target.value)} customStyles={{ control: { minWidth: '200px' } }} options={[{ label: 'All', value: 'All' }]} />
        <div class='ml-4'>
          <SecondaryButton loading={loading} onClick={() => generateRequest(agency.label)}>
            {loading ? 'Generating request...' : 'Generate request'}
          </SecondaryButton>
        </div>
      </div>

      <div className='sm:col-span-2'>
        <dt className='text-sm font-medium text-gray-500'>Requests</dt>
        <dd className='mt-1 text-sm text-gray-900'>
          {(!event.requests || !event.requests.length) && <div class='text-sm text-gray-700'>There have not been any requests generated for this event</div>}
          {event.requests.length > 0 && (
            <ul role='list' className='border border-gray-200 rounded-md divide-y divide-gray-200'>
              {event.requests.map(request => (
                <FileListItem request={request} event={event} />
              ))}
            </ul>
          )}
        </dd>
      </div>
    </div>
  );
};

export default FileList;
