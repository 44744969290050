import React from 'react';

const EventTabs = ({ handleTabChange, currentTab, tabs }) => {
  return (
    <div class='mb-6'>
      <div class='sm:hidden'>
        <select
          class='mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150'
          value={currentTab}
        >
          {tabs.map((tab) => {
            return <option>{tab}</option>;
          })}
        </select>
      </div>

      <div class='hidden sm:block'>
        <div class='border-b border-gray-200'>
          <nav class='-mb-px flex items-center justify-center'>
            {tabs.map((tab) => {
              if (tab === currentTab) {
                return (
                  <div
                    onClick={handleTabChange}
                    class='cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700'
                  >
                    {tab}
                  </div>
                );
              } else {
                return (
                  <div
                    onClick={handleTabChange}
                    class='cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300'
                  >
                    {tab}
                  </div>
                );
              }
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default EventTabs;
