import React from 'react';

const AgencyButtons = ({ handleSubmit, handleCancel, handleDelete }) => {
  return (
    <div class='mt-8 border-t border-gray-200 pt-5'>
      <div class='inline-flex justify-start'>
        <span class='inline-flex rounded-md shadow-sm'>
          <button
            onClick={handleSubmit}
            type='button'
            class='inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
          >
            Save Agency
          </button>
        </span>
        <span class='ml-3 inline-flex rounded-md shadow-sm'>
          <button
            onClick={handleCancel}
            type='button'
            class='py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out'
          >
            Cancel
          </button>
        </span>
      </div>
      <div class='float-right flex justify-end'>
        <span class='inline-flex rounded-md shadow-sm'>
          <button
            onClick={handleDelete}
            type='button'
            class='inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out'
          >
            Delete Agency
          </button>
        </span>
      </div>
    </div>
  );
};

export default AgencyButtons;
