import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import UserSettings from './UserSettings'

const DesktopNavMenu = ({ user, handleSignOut }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const classNames = (...classes) => classes.filter(Boolean).join(' ')

  const links = {
    '/events': 'Events',
    '/past-events': 'Past Events',
    '/workers': 'Workers',
    '/positions': 'Positions',
    '/agencies': 'Agencies'
  }

  return (
    <>
      <div class='flex cursor-pointer' onClick={() => navigate('/home')}>
        <div class='flex flex-shrink-0 items-center p-4'>
          <img
            class='ml-5 block h-28 w-auto'
            src='https://s3.amazonaws.com/ts-prod-assets.tripleseat.com/location_logos/000/007/704/7704_a79db9c8a8_medium.png'
            alt='Encore Catering logo'
          />
        </div>
      </div>

      {user.first_name && (
        <div id='menu' class='hidden sm:block'>
          <nav class='flex'>
            {Object.keys(links).map(slug => {
              const isActiveTab = location.pathname.includes(slug)
              return (
                <Link
                  to={slug}
                  className={classNames(
                    isActiveTab && 'border-b-2 border-indigo-500',
                    'px-3 py-2 text-sm font-medium leading-5 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none lg:ml-4'
                  )}
                >
                  {links[slug]}
                </Link>
              )
            })}
          </nav>
        </div>
      )}

      <div id='notifications' class='hidden sm:ml-6 sm:flex sm:items-center'>
        <button
          class='hidden rounded-full border-2 border-transparent p-1 text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none lg:ml-6 lg:flex lg:items-center'
          aria-label='Notifications'
        >
          <svg class='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
            />
          </svg>
        </button>

        <UserSettings user={user} logout={handleSignOut} />
      </div>
    </>
  )
}

export default DesktopNavMenu
