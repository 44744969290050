import React from 'react';
import Select from 'react-select';

const GenericSelect = ({ id, value, onChange, options = [], isClearable = true, customStyles = {} }) => {
  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      ...(customStyles.control || {}),
      'outline': 'none',
      'boxShadow': isFocused ? '0 0 0 3px rgba(164, 202, 254, 0.45)' : 'none',
      'borderColor': isFocused ? '#a4cafe' : styles.borderColor,
      ':hover': {
        borderColor: isFocused ? '#a4cafe' : '#d2d6dc',
      },
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'lightgray',
        color: 'black',
      },
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    singleValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    multiValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    option: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  };

  const handleChange = (e) => {
    const value = e ? e.value : e;
    onChange({
      target: { id, value },
    });
  };

  const valueObj = options.find((option) => option.value === value);

  return <Select value={valueObj} onChange={handleChange} options={options} styles={styles} isClearable={isClearable} />;
};

export default GenericSelect;
