import axios from './axios'
import { SET_USER, SET_USERS, UPSERT_USER, RESET_USER, SET_USERS_FOR_POSITION } from './types'
import { setAlert } from './alert'

export const fetchUsers = filter => async dispatch => {
  try {
    const res = await axios.get('/api/user', { params: filter })
    console.log(res.data)
    dispatch({
      type: SET_USERS,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchUser = filter => async dispatch => {
  try {
    const res = await axios.get('/api/user', { params: filter })
    dispatch({
      type: SET_USER,
      payload: res.data[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchUsersByPosition = positionId => async dispatch => {
  try {
    const res = await axios.get(`/api/user/position/${positionId}`)
    dispatch({
      type: SET_USERS_FOR_POSITION,
      payload: {
        positionId,
        users: res.data
      }
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const setState = user => async dispatch => {
  dispatch({
    type: SET_USER,
    payload: user
  })
}

export const setUsers = users => async dispatch => {
  dispatch({
    type: SET_USERS,
    payload: users
  })
}

export const resetUser = () => async dispatch => {
  dispatch({
    type: RESET_USER
  })
}

export const upsertUser = user => async dispatch => {
  try {
    console.log({ user })
    const res = await axios.post(`/api/user`, user)
    dispatch({
      type: UPSERT_USER,
      payload: res.data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
    return res.data
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertUsers = users => async dispatch => {
  try {
    let res = await Promise.all(users.map(async user => await axios.post(`/api/user`, user)))
    let data = res.map(r => r.data)
    console.log(res)
    dispatch({
      type: UPSERT_USER,
      payload: data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
