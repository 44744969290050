import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

const InstaworkShifts = ({ event, shifts }) => {
  const [loading, setLoading] = useState(false);

  function round(num, decimals) {
    if (!decimals) decimals = 2;
    return +(Math.round(num + `e+${decimals}`) + `e-${decimals}`);
  }

  return (
    <div>
      <h2 class='mt-6 font-bold'>Instawork Shifts</h2>
      <p class='text-gray-700 text-sm'>The following are the shifts scheduled in instawork for {moment(event.event_date).format('dddd, MMMM D')} for this event</p>
      <div class='mt-6'>
        <div class='flex font-semibold text-sm'>
          <div class='w-1/6'>Professional</div>
          <div class='w-1/6'>Shift Start</div>
          <div class='w-1/6'>Shift End</div>
          <div class='w-1/6'>Hours</div>
          <div class='w-1/6'>Rate</div>
          <div class='w-1/6'>Total</div>
        </div>
        {shifts.map(shift => {
          const startTime = shift.actualStartTime || shift.expectedStartTime;
          const endTime = shift.actualEndTime || shift.expectedEndTime;
          const hours = round((moment(endTime) - moment(startTime)) / 1000 / 60 / 60, 2);
          const total = (shift.rate * hours).toFixed(2);
          return (
            <div class='flex text-sm'>
              <div class='w-1/6'>{shift.user.name}</div>
              <div class='w-1/6'>{moment(startTime).format('h:mma')}</div>
              <div class='w-1/6'>{moment(endTime).format('h:mma')}</div>
              <div class='w-1/6'>{hours.toFixed(2)} hrs</div>
              <div class='w-1/6'>$ {shift.rate}</div>
              <div class='w-1/6'>$ {total}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  event: state.event.event,
  shifts: state.instawork.shifts,
});

export default connect(mapStateToProps, {})(InstaworkShifts);
