import { SET_ALERT, REMOVE_ALERT, SET_ALERT_GENERATOR, REMOVE_ALL } from '../actions/types';

const initialState = {};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    case REMOVE_ALL:
      state.removeAll();
      return state;
    case SET_ALERT_GENERATOR:
      return payload;
    default:
      return state;
  }
}
