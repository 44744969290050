import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const EventRow = ({ _id, name, event_date, company, encore_contact, revision: initialRevision, booked, updateEvent }) => {
  const navigate = useNavigate()
  const navigateToEvent = () => navigate(`/events/${_id}`)

  const [revision, setRevision] = useState(initialRevision)

  const [checked, setChecked] = useState(booked)

  function handleChecked() {
    updateEvent({ _id, booked: !checked })
    setChecked(!checked)
  }

  function handleRevisionChecked() {
    setRevision({ ...revision, complete: !revision.complete })
    updateEvent({ _id, revision: { ...revision, complete: !revision.complete } })
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <tr class={classNames(checked && 'opacity-75', 'cursor-pointer hover:bg-gray-100')}>
      <td
        class='whitespace-no-wrap border-b border-gray-200 px-2 py-4 text-center text-sm font-medium leading-5 text-gray-900'
        style={{ lineHeight: '0.75' }}
      >
        <input
          type='checkbox'
          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
          checked={checked}
          onChange={handleChecked}
          style={{ accentColor: '#5850ec' }}
        />
      </td>
      <td onClick={navigateToEvent} class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-sm font-medium leading-5 text-gray-900'>
        {name}
      </td>
      <td
        onClick={navigateToEvent}
        class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-center text-sm font-medium leading-5 text-gray-900'
      >
        {revision && (
          <div className='flex'>
            <input
              type='checkbox'
              className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6'
              checked={revision.complete}
              onChange={handleRevisionChecked}
              style={{ accentColor: '#5850ec' }}
            />
            <span className='ml-2'>{moment(revision.date).format('MMM D, h:mm A')}</span>
          </div>
        )}
      </td>
      <td onClick={navigateToEvent} class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-sm font-medium leading-5 text-gray-900'>
        {encore_contact}
      </td>
      <td onClick={navigateToEvent} class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-sm leading-5 text-gray-900'>
        {moment(event_date).format('MMMM D, YYYY @ h:mm A')}
      </td>
      <td onClick={navigateToEvent} class='whitespace-no-wrap border-b border-gray-200 px-6 py-4 text-sm leading-5 text-gray-900'>
        {company}
      </td>
    </tr>
  )
}

export default EventRow
