import React, { useState, useEffect } from 'react';
import ShiftGroup from './ShiftGroup';
import moment from 'moment';
import ShiftsHeader from './ShiftsHeader';
import AddShiftsModal from './AddShiftsModal';

const Shifts = ({ event, handleChange, setShift, removeShift }) => {
  const [shiftGroups, setShiftGroups] = useState([]);
  const [isModalOpen, setModalState] = useState(false);
  const [groupBy, setGroupBy] = useState('position');

  const groupShifts = (shifts, groupBy) => {
    const groups = shifts.reduce((obj, shift) => {
      const value = shift[groupBy].name;
      obj[value] = obj[value] ? [...obj[value], shift] : [shift];
      return obj;
    }, {});
    return Object.values(groups);
  };

  // group shifts by position
  useEffect(() => {
    const shifts = event.shifts.filter((shift) => shift.status !== 'Complete');
    if (groupBy === 'none' && shifts.length) setShiftGroups([shifts]);
    else if (groupBy === 'none' && !shifts.length) setShiftGroups([]);
    else {
      const shiftGroups = groupShifts(shifts, groupBy);
      setShiftGroups(shiftGroups);
    }
  }, [event.shifts, groupBy]);

  return (
    <>
      {isModalOpen && <AddShiftsModal event={event} shifts={event.shifts} setModalState={setModalState} handleChange={handleChange} />}
      <ShiftsHeader setModalState={setModalState} setGroupBy={setGroupBy} groupBy={groupBy} />
      {!shiftGroups.length && <div class='mt-6 ml-2 text-sm text-gray-700'>There are currently no shifts for this event</div>}
      <dl class='mt-6 space-y-6 divide-y divide-gray-200'>
        {shiftGroups.map((shifts) => (
          <ShiftGroup shifts={shifts} setShift={setShift} removeShift={removeShift} groupBy={groupBy} eventDate={event.event_date} />
        ))}
      </dl>
    </>
  );
};

export default Shifts;
