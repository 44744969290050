import axios from './axios'
import { SET_LOG, SET_LOGS, UPSERT_LOG, UPSERT_LOGS, RESET_LOG, DELETE_LOG } from './types'
import { setAlert } from './alert'

export const fetchLogs = filter => async dispatch => {
  try {
    const res = await axios.get('/api/log', { params: filter })
    dispatch({
      type: SET_LOGS,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchLog = filter => async dispatch => {
  try {
    const res = await axios.get('/api/log', { params: filter })
    dispatch({
      type: SET_LOG,
      payload: res.data[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchLogById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/log/${id}`)
    dispatch({
      type: SET_LOG,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const setState = log => async dispatch => {
  dispatch({
    type: SET_LOG,
    payload: log
  })
}

export const setLogs = logs => async dispatch => {
  dispatch({
    type: SET_LOGS,
    payload: logs
  })
}

export const resetLog = () => async dispatch => {
  dispatch({
    type: RESET_LOG
  })
}

export const upsertLog = log => async dispatch => {
  try {
    await axios.post(`/api/log`, log)
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertLogs = logs => async dispatch => {
  try {
    let res = await Promise.all(logs.map(async log => await axios.post(`/api/log`, log)))
    let data = res.map(r => r.data)
    console.log(res)
    dispatch({
      type: UPSERT_LOGS,
      payload: data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const deleteLog = id => async dispatch => {
  try {
    await axios.delete(`/api/log/${id}`)
    dispatch({
      type: DELETE_LOG,
      payload: { id }
    })
    dispatch(setAlert('success', 'Successfully deleted the log'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
