import React, { useState } from 'react'
import { connect } from 'react-redux'
import Task from './Task'
import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const Tasks = ({ tasks, handleChange, full_name }) => {
  const [description, setDescription] = useState('')

  function addTask() {
    if (!description) return
    const task = {
      _id: new ObjectId().toString(),
      date: new Date(),
      complete: false,
      description: description,
      user: full_name
    }
    handleChange({
      target: {
        id: 'tasks',
        value: [...tasks, task]
      }
    })
  }

  function deleteTask(id) {
    handleChange({
      target: {
        id: 'tasks',
        value: tasks.filter(task => task._id != id)
      }
    })
  }

  function updateTask(task) {
    handleChange({
      target: {
        id: 'tasks',
        value: tasks.map(t => (t._id == task._id ? task : t))
      }
    })
  }

  const currentTasks = (tasks || []).filter(task => !task.complete)
  const completedTasks = (tasks || []).filter(task => task.complete)

  return (
    <div className=''>
      <div className='flex items-center text-sm text-gray-900 mb-4'>
        <input
          placeholder='Add a new task...'
          id='description'
          onChange={e => setDescription(e.target.value)}
          value={description}
          style={{ width: '350px' }}
          class='form-input block border-indigo-500 transition duration-150 h-11 ease-in-out sm:text-sm sm:leading-5'
        />
        <div
          onClick={addTask}
          className='h-11 select-none rounded-md w-11 ml-1 bg-indigo-700 text-white text-2xl flex items-center justify-center cursor-pointer hover:bg-indigo-500'
        >
          +
        </div>
      </div>

      <div className='grid grid-cols-2 space-x-4'>
        <div className='my-2 text-sm text-gray-900 border rounded-md'>
          <div className='text-lg leading-6 font-medium text-gray-900 p-4 bg-gray-100'>Current Tasks</div>
          <div className='overflow-y-scroll p-4' style={{ height: '350px' }}>
            {currentTasks.map(task => (
              <Task task={task} deleteTask={deleteTask} updateTask={updateTask} />
            ))}
          </div>
        </div>

        <div className='my-2 text-sm text-gray-900 border rounded-md'>
          <div className='text-lg leading-6 font-medium text-gray-900 p-4 bg-gray-100'>Completed Tasks</div>
          <div className='overflow-y-scroll p-4' style={{ height: '350px' }}>
            {completedTasks.map(task => (
              <Task task={task} deleteTask={deleteTask} updateTask={updateTask} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  full_name: state.auth.user.full_name
})

export default connect(mapStateToProps, {})(Tasks)
