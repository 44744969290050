import React from 'react';
import { connect } from 'react-redux';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import moment from 'moment';

const ShiftGroupHeader = ({ visible, setVisible, shifts, groupBy, eventDate, user }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  const shift = shifts[0];

  const getShiftHours = ({ expected_start_time, expected_end_time }) => {
    console.log(expected_start_time, expected_end_time);
    return expected_start_time && expected_end_time ? parseFloat(((moment(expected_end_time) - moment(expected_start_time)) / 1000 / 60 / 60).toFixed(2)) : 0;
  };

  const totalHours = shifts.reduce((sum, shift) => sum + getShiftHours(shift), 0);

  const totalCost = shifts.reduce((sum, shift) => {
    const hours = getShiftHours(shift);
    const rate = +shift.rate || 0;
    const additional_costs = +shift.additional_costs || 0;
    const wages = hours * rate;
    console.log(rate, additional_costs, wages);
    return sum + wages + additional_costs;
  }, 0);

  const avgRate = totalCost / totalHours;

  return (
    <div className='pt-6 cursor-pointer' onClick={() => setVisible(!visible)}>
      <dt className='text-lg select-none'>
        <div className='text-left w-full flex justify-between items-center text-gray-400'>
          <div className='flex items-center'>
            <div className='flex-shrink-0'>
              <div class='flex flex-col items-center justify-center bg-gray-700 text-white p-4 h-12 w-12 rounded-full'>
                <svg aria-hidden='true' focusable='false' data-prefix='fad' data-icon='glass-martini-alt' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                  <g class='fa-group'>
                    <path fill='currentColor' d='M256 213.13L154.84 112h202.27z' class='fa-secondary'></path>
                    <path
                      fill='currentColor'
                      d='M405.12 64L256 213.13 106.84 64h298.28m73.06-64H33.78C3.73 0-11.32 36.34 10 57.6l214 214V464h-56a40 40 0 0 0-40 40 8 8 0 0 0 8 8h240a8 8 0 0 0 8-8 40 40 0 0 0-40-40h-56V271.64l214-214C523.28 36.34 508.23 0 478.18 0z'
                      class='fa-primary'
                    ></path>
                  </g>
                </svg>
              </div>
            </div>

            <div className='ml-4 select-none'>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>{shift[groupBy].name}</h3>
              <p className='text-sm text-gray-500'>
                <span>{moment(eventDate).format('MMMM D, YYYY')}</span>
              </p>
            </div>
          </div>

          <div class='flex-shrink-0'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>{`${shifts.length} Shifts`}</h3>
            <p className='text-sm text-gray-500'>{`Total: ${totalHours} hrs`}</p>
          </div>

          {user.account_type === 'Admin' && (
            <div class='flex-shrink-0'>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>{`$${totalCost.toFixed(2)}`}</h3>
              <p className='text-sm text-gray-500'>{`Avg: $${avgRate.toFixed(2)}/hr`}</p>
            </div>
          )}
          <span className='mx-6 h-7 flex items-center'>
            <ChevronDownIcon className={classNames(visible ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} aria-hidden='true' />
          </span>
        </div>
      </dt>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ShiftGroupHeader);
