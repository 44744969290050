import React from 'react';
import GenericSelect from '../../layout/GenericSelect';

export default function ShiftsHeader({ setModalState, setGroupBy, groupBy }) {
  const groupByOptions = [
    { label: 'Position', value: 'position' },
    { label: 'Agency', value: 'agency' },
    { label: 'None', value: 'none' },
  ];

  return (
    <div className='bg-white px-4 py-5 border-b border-gray-200 sm:px-6'>
      <div className='-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap'>
        <div className='mt-4'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>Shifts</h3>
          <p className='mt-1 text-sm text-gray-500'>Add the number of desired shifts for each position that are needed for this event.</p>
        </div>
        <div className='mt-4 flex'>
          <div class='relative'>
            <div class='text-xs absolute' style={{ top: '-20px' }}>
              Grouped By
            </div>
            <GenericSelect id='groupBy' onChange={(e) => setGroupBy(e.target.value)} value={groupBy} options={groupByOptions} isClearable={false} customStyles={{ control: { minWidth: '200px' } }} />
          </div>
          <button
            onClick={() => setModalState(true)}
            type='button'
            className='ml-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            Add Shifts
          </button>
        </div>
      </div>
    </div>
  );
}
