import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../actions/auth'
import MobileNavMenu from './MobileNavMenu'
import DesktopNavMenu from './DesktopNavMenu'
import MobileIcon from './MobileIcon'

const Navbar = ({ logout, user }) => {
  let navigate = useNavigate()

  const handleSignOut = () => {
    logout()
    navigate('/login')
  }

  const [open, setOpen] = useState(false)

  return (
    <nav class='fixed inset-x-0 top-0 z-40 bg-white shadow-sm '>
      <div class='mx-auto sm:px-4 sm:py-4 lg:px-8'>
        <div class='flex h-20 items-center justify-between'>
          {<DesktopNavMenu user={user} handleSignOut={handleSignOut} />}
          {/* {user.first_name && <MobileIcon open={open} setOpen={setOpen} />} */}
        </div>
        {/* {open && <MobileNavMenu user={user} handleSignOut={handleSignOut} setOpen={setOpen} />} */}
      </div>
    </nav>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps, { logout })(Navbar)
