import React from 'react';

const PrimaryButton = ({ id, onClick, children, style, loading, classes = '' }) => {
  const defaultClasses = {
    root:
      'inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-sm leading-6 font-medium text-white shadow-sm hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 cursor-pointer',
  };

  const prefixes = classes.split(' ').map((cls) => cls.split('-')[0]);

  classes = {
    root:
      defaultClasses.root
        .split(' ')
        .filter((cls) => !prefixes.includes(cls.split('-')[0]))
        .join(' ') +
      ' ' +
      classes,
  };

  return (
    <button id={id} onClick={onClick} style={style} type='button' class={classes.root}>
      {loading && <span class='spinner-button -ml-2 mr-2'></span>}
      {children}
    </button>
  );
};

export default PrimaryButton;
