import React, { useEffect, useState } from 'react';
import Row from './Row';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPositions, deletePosition, upsertPosition, setPositions, resetPosition } from '../../actions/position';
import Spinner from '../layout/Spinner';
import Container from '../layout/Container';

const PositionTable = ({ positions, fetchPositions, deletePosition, resetPosition, setPositions, upsertPosition }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchPositions();
      setLoading(false);
    })();
  }, [fetchPositions]);

  useEffect(() => {
    resetPosition();
  }, []);

  const handleDelete = (id) => {
    const position = positions.find((cat) => cat._id === id);
    if (position) {
      upsertPosition({
        ...position,
        active: false,
      });
      setPositions(positions.filter((cat) => cat._id !== id));
    }
  };

  return (
    <Container variant='blank' classes='-mx-32'>
      {loading && <Spinner />}
      <div class='flex flex-col my-10 px-0'>
        <Link
          to='/positions/new'
          class='inline-flex w-60 shadow mb-2 items-center justify-center px-8 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer'
        >
          + Add new position
        </Link>
        <div class='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div class='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            <table class='min-w-full'>
              <thead>
                <tr>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Name</th>
                  {/* <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Status</th> */}
                  {/* <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th> */}
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th>
                </tr>
              </thead>
              <tbody class='bg-white'>
                {positions.map((position) => (
                  <Row key={position._id} {...position} handleDelete={handleDelete} />
                ))}
              </tbody>
            </table>
          </div>
          {!positions.length && <p class='mt-2'>There are no positions. Click the button above to add positions.</p>}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  positions: state.position.positions,
});

export default connect(mapStateToProps, { fetchPositions, deletePosition, resetPosition, upsertPosition, setPositions })(PositionTable);
