import React from 'react';

const TextArea = ({ label, id, value, handleChange }) => {
  return (
    <div>
      {label && (
        <label for={id} class='block text-sm font-medium leading-5 text-gray-700'>
          {label}
        </label>
      )}
      <div class='mt-1 rounded-md shadow-sm'>
        <textarea id={id} onChange={handleChange} value={value || ''} class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5' />
      </div>
    </div>
  );
};

export default TextArea;
