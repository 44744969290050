import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setState, upsertEvent, fetchOrCreateEvents } from '../../actions/event';
import { fetchShifts, setShifts, upsertShifts } from '../../actions/shift';
import EventTabs from '../events/EventTabs';
import Container from '../layout/Container';
import EventDetails from '../events/EventDetails';
import EventButtons from '../events/EventButtons';

const Booking = ({ event, events, setState, upsertEvent, fetchOrCreateEvents, shifts, fetchShifts, setShifts, upsertShifts }) => {
  const { id } = useParams();

  // Search for events where booking_id === id and store in state (events)
  // else, create new events for the booking and put into state (events)

  useEffect(() => {
    fetchOrCreateEvents(id);
  }, [id]);

  const handleEventChange = (id) => {
    const event = events.find((event) => event._id === id);
    setState(event);
    fetchShifts({ event: id });
  };

  const handleChange = (e) =>
    setState({
      ...event,
      [e.target.id]: e.target.value,
    });

  const handleSubmit = () => {
    upsertEvent(event);
    upsertShifts(shifts);
  };

  return (
    <Container>
      <form>
        <div>
          <h1 class='text-2xl text-gray-700 font-bold leading-loose text-center tracking-normal'>{events.length ? events[0].booking_name : 'Loading...'}</h1>
          <EventTabs currentEvent={event} events={events} handleEventChange={handleEventChange} />
          <EventDetails event={event} handleChange={handleChange} setShifts={setShifts} shifts={shifts} />
        </div>
        <EventButtons handleSubmit={handleSubmit} />
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  events: state.event.events,
  event: state.event.event,
  shifts: state.shift.shifts,
});

export default connect(mapStateToProps, { setState, upsertEvent, fetchOrCreateEvents, fetchShifts, setShifts, upsertShifts })(Booking);
