import axios from 'axios';

export const get = async (path) => {
  return await axios.post('/api/tripleseat', {
    method: 'get',
    url: `https://api.tripleseat.com/v1${path}`,
  });
};

export const post = async (path, payload) => {
  return await axios.post('/api/tripleseat', {
    method: 'post',
    url: `https://api.tripleseat.com/v1${path}`,
    payload: payload,
  });
};

export const getRecord = async (module, id) => {
  const path = '/' + module + '/' + id;
  const res = await get(path);
  const record = res.data[module.slice(0, -1)];

  // Add custom fields as regular fields
  const fields = record['custom_fields'] || [];
  fields.forEach(({ custom_field_name, value }) => (record[custom_field_name] = value));

  return record;
};

export const getCoordinator = async (event) => await getRecord('users', event.owned_by);
