import React, { useState, useEffect } from 'react';
import ShiftGroupHeader from './ShiftGroupHeader';
import ShiftGroupBody from './ShiftGroupBody';

const ShiftGroup = ({ shifts, setShift, removeShift, groupBy, eventDate }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div>
      {groupBy !== 'none' && <ShiftGroupHeader setVisible={setVisible} visible={visible} shifts={shifts} groupBy={groupBy} eventDate={eventDate} />}
      <ShiftGroupBody visible={visible} groupBy={groupBy} shifts={shifts} setShift={setShift} removeShift={removeShift} />
    </div>
  );
};

export default ShiftGroup;
