import React from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

// Check to see if the user is not authenticated and not loading

const PrivateRoute = ({ auth, children }) => {
  const { isAuthenticated, loading } = auth
  console.log('isAuthenticated: ', isAuthenticated)
  console.log('loading: ', loading)
  if (!isAuthenticated && !loading) {
    return <Navigate to='/login' />
  } else {
    return children
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)
