import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAgencies } from '../../actions/agency';
import Select from 'react-select';

// value = { _id: 302242304238aff }

const AgencySelect = ({ id, value, onChange, agencies, fetchAgencies, isClearable, customStyles = {}, options = [] }) => {
  const optionsList = [...agencies, ...options];

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      ...(customStyles.control || {}),
      'outline': 'none',
      'boxShadow': isFocused ? '0 0 0 3px rgba(164, 202, 254, 0.45)' : 'none',
      'borderColor': isFocused ? '#a4cafe' : styles.borderColor,
      ':hover': {
        borderColor: isFocused ? '#a4cafe' : '#d2d6dc',
      },
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'lightgray',
        color: 'black',
      },
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    singleValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    multiValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    option: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  };

  const handleChange = (e) => {
    console.log(e);
    onChange({
      target: { id, value: e },
    });
  };

  const valueObj = optionsList.find((option) => option.value === value._id);

  return <Select value={valueObj} onChange={handleChange} options={optionsList} styles={styles} isClearable={isClearable} />;
};

const mapStateToProps = (state) => ({
  agencies: state.agency.agencies,
});

export default connect(mapStateToProps, { fetchAgencies })(AgencySelect);
