import React, { useEffect, useState } from 'react';
import Row from './Row';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAgencies, deleteAgency, upsertAgency, setAgencies, resetAgency } from '../../actions/agency';
import Spinner from '../layout/Spinner';
import Container from '../layout/Container';

const AgencyTable = ({ agencies, fetchAgencies, deleteAgency, resetAgency, setAgencies, upsertAgency }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchAgencies();
      setLoading(false);
    })();
  }, [fetchAgencies]);

  useEffect(() => {
    resetAgency();
  }, []);

  const handleDelete = (id) => {
    const agency = agencies.find((cat) => cat._id === id);
    if (agency) {
      upsertAgency({
        ...agency,
        active: false,
      });
      setAgencies(agencies.filter((cat) => cat._id !== id));
    }
  };

  return (
    <Container variant='blank' classes='-mx-32'>
      {loading && <Spinner />}
      <div class='flex flex-col my-10 px-0'>
        <Link
          to='/agencies/new'
          class='inline-flex w-60 shadow mb-2 items-center justify-center px-8 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer'
        >
          + Add new agency
        </Link>
        <div class='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div class='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            <table class='min-w-full'>
              <thead>
                <tr>
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Name</th>
                  {/* <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Status</th> */}
                  {/* <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th> */}
                  <th class='px-6 py-3 border-b border-gray-200 bg-gray-50'></th>
                </tr>
              </thead>
              <tbody class='bg-white'>
                {agencies.map((agency) => (
                  <Row key={agency._id} {...agency} handleDelete={handleDelete} />
                ))}
              </tbody>
            </table>
          </div>
          {!agencies.length && <p class='mt-6 text-md  text-gray-700'>There are no agencies. Click the button above to add agencies.</p>}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  agencies: state.agency.agencies,
});

export default connect(mapStateToProps, { fetchAgencies, deleteAgency, resetAgency, upsertAgency, setAgencies })(AgencyTable);
