import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { setState, upsertAgency, fetchAgencyById, resetAgency, deleteAgency } from '../../actions/agency'
import Container from '../layout/Container'
import Spinner from '../layout/Spinner'
import AgencyButtons from './AgencyButtons'

const Agency = ({ agency, setState, upsertAgency, fetchAgencyById, resetAgency, deleteAgency }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  // On component mount or change in id, fetch the agency information from the database

  useEffect(() => {
    resetAgency()
    if (id !== 'new') fetchAgencyById(id)
  }, [id, fetchAgencyById, resetAgency])

  // Redirect the page from the agency creation page to the agency id specific page once the agency has been created

  useEffect(() => {
    if (id === 'new' && agency._id) navigate(`/agencies/${agency._id}`)
  }, [agency._id])

  // On user change, update state without committing changes to the database

  const handleChange = e =>
    setState({
      ...agency,
      [e.target.id]: e.target.value
    })

  // If user saves changes, update the agency in the database

  const handleSubmit = async () => {
    await upsertAgency(agency)
    await resetAgency()
    navigate('/agencies')
  }

  // If user cancels current agency editing, then clear state

  const handleCancel = async () => {
    await resetAgency()
    navigate('/agencies')
  }

  const handleDelete = async () => {
    await deleteAgency(agency._id)
    navigate('/agencies')
  }

  const header = id === 'new' ? 'Add New Agency' : 'Edit Agency'

  return (
    <>
      {id !== 'new' && !agency._id && <Spinner />}
      <Container>
        <div>
          <h1 class='text-center text-2xl font-bold leading-loose tracking-normal text-gray-700'>{header}</h1>
          <div class='sm:col-span-6'>
            <label class='block text-xs font-medium leading-5 text-gray-700'>Agency Name</label>
            <div class='mt-1 rounded-md shadow-sm'>
              <input
                id='name'
                onChange={handleChange}
                value={agency.name || ''}
                class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              />
            </div>
          </div>
          <div class='mt-6 sm:col-span-6'>
            <label class='block text-xs font-medium leading-5 text-gray-700'>Email</label>
            <div class='mt-1 rounded-md shadow-sm'>
              <input
                id='email'
                onChange={handleChange}
                value={agency.email || ''}
                class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              />
            </div>
          </div>
        </div>
        <AgencyButtons handleSubmit={handleSubmit} handleCancel={handleCancel} handleDelete={handleDelete} />
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  agency: state.agency.agency
})

export default connect(mapStateToProps, { setState, upsertAgency, fetchAgencyById, resetAgency, deleteAgency })(Agency)
