import React from 'react';
import ShiftActionsDropdown from './ShiftActionsDropdown';
import Input from '../layout/Input';
import WorkerSelect from '../layout/WorkerSelect';
import PositionSelect from '../layout/PositionSelect';
import SentIcon from '../layout/icons/Sent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const ListOfShifts = ({ shifts, updateShift, removeShift }) => {
  function renderShifts(shift, i) {
    const { start_time, end_time } = shift;
    const hours = start_time && end_time ? parseFloat(((moment(end_time) - moment(start_time)) / 1000 / 60 / 60).toFixed(2)) : null;

    const handleWorkerChange = (e) => {
      const user = e.target.value;
      if (user && shift.position) {
        shift.rate = user.positions.find((userPosition) => userPosition.position._id === shift.position._id).rate;
      }
      updateShift(e, {
        ...shift,
        rate: shift.rate,
      });
    };

    const handlePositionChange = (e) => {
      const position = e.target.value;
      if (shift.user && position) {
        shift.rate = shift.user.positions.find((userPosition) => userPosition.position._id === position._id).rate;
      }
      updateShift(e, {
        ...shift,
        rate: shift.rate,
      });
    };

    return (
      <div class='grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-11'>
        {/* <div class='sm:col-span-1'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              Status
            </label>
          )}
          <div class='mt-1 flex items-center h-10  justify-center rounded-md bg-green-100 font-medium lowercase text-sm text-green-700'>
            <span>sent</span>
          </div>
        </div> */}

        {/* <div class='sm:col-span-2'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              Position
            </label>
          )}
          <div class='mt-1 rounded-md '>
            {' '}
            <PositionSelect id='position' value={shift.position || {}} onChange={handlePositionChange} />
          </div>
        </div> */}

        <div class='sm:col-span-2'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              Agency
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <PositionSelect id='position' value={shift.position || {}} onChange={handlePositionChange} />
          </div>
        </div>

        <div class='sm:col-span-2'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              Worker
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <WorkerSelect id='user' value={shift.user || {}} positionId={shift.position && shift.position._id} onChange={handleWorkerChange} />
          </div>
        </div>

        <div class='sm:col-span-1'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              Start Time
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <DatePicker
              id='expected_start_time'
              className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              selected={shift.expected_start_time ? new Date(shift.expected_start_time) : ''}
              onChange={(date) => updateShift({ target: { id: 'expected_start_time', value: date } }, shift)}
              dateFormat='h:mm aa'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
            />
          </div>
        </div>

        <div class='sm:col-span-1'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              End Time
            </label>
          )}
          <div class='mt-1 rounded-md '>
            <DatePicker
              id='expected_end_time'
              className='shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              selected={shift.expected_end_time ? new Date(shift.expected_end_time) : ''}
              onChange={(date) => updateShift({ target: { id: 'expected_end_time', value: date } }, shift)}
              dateFormat='h:mm aa'
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
            />
          </div>
        </div>

        {/* <div class='sm:col-span-1'>
          <Input label={i > 0 ? '' : 'Est. Hours'} value={hours} />
        </div> */}

        <div class='sm:col-span-1'>
          <Input handleChange={(e) => updateShift(e, shift)} id='rate' label={i > 0 ? '' : 'Hourly Rate'} value={shift.rate} />
        </div>
        <div class='sm:col-span-1'>
          <Input handleChange={(e) => updateShift(e, shift)} id='rate' label={i > 0 ? '' : 'Gratuity'} value={shift.gratuity} />
        </div>
        <div class='sm:col-span-1'>
          <Input handleChange={(e) => updateShift(e, shift)} id='rate' label={i > 0 ? '' : 'Flat Fees'} value={shift.additional_costs} />
        </div>
        <div class='sm:col-span-2'>
          {i === 0 && (
            <label for='selection_deadline' class='block text-sm font-medium leading-5 text-gray-700'>
              &nbsp;
            </label>
          )}
          <div class='mt-1'>
            <ShiftActionsDropdown />
          </div>
        </div>

        {/* <div class='sm:col-span-1'>
          <div class='cursor-pointer text-indigo-700 text-xs mt-1 h-full flex items-center justify-left'>
            <div class={`hover:opacity-75 ${i === 0 ? 'mt-5' : ''}`} onClick={() => removeShift(shift._id)}>
              X
            </div>
          </div>
        </div> */}
      </div>
    );
  }

  return shifts.map((shift, i) => renderShifts(shift, i));
};

export default ListOfShifts;
