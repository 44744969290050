import React, { useState, useEffect, useMemo } from 'react';
import EventRow from './EventRow';
import { Link } from 'react-router-dom';
import Container from '../layout/Container';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from '../../actions/axios';
import { debounce } from '../../utils/functions';

const PastEvents = () => {
  const [events, setEvents] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState('');

  async function fetchEvents(filter) {
    if (!filter) filter = { event_date: { $lte: moment().startOf('day').toISOString() } };
    const sort = { event_date: -1 };
    const limit = 20;
    const page = pageNumber + 1;
    const res = await axios.get('/api/event', { params: { filter, limit, page, sort } });
    setEvents([...events, ...res.data.events]);
    setPageNumber(page);
    setHasMore(page < res.data.totalPages);
  }

  async function updateEvent(event) {
    await axios.post(`/api/event`, event);
  }

  async function searchEvent(query) {
    if (query) {
      fetchEvents({
        name: { $regex: query, $options: 'i' },
      });
    } else {
      fetchEvents();
    }
  }
  searchEvent = useMemo(() => debounce(searchEvent, 300), []);

  async function handleChange(e) {
    const value = e.target.value;
    setQuery(value);
    searchEvent(value);
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Container variant='blank'>
      <div class='flex flex-col mb-10 px-6 sm:px-0'>
        <h4 class='text-3xl text-gray-800 font-semibold mb-4'>Past Events</h4>
        <div class='flex items-center justify-between mb-2'>
          <input placeholder='Search for an event...' id='search' onChange={handleChange} value={query || ''} style={{ width: '400px' }} class='form-input shadow-md block border-indigo-500 transition duration-150 h-11 ease-in-out sm:text-sm sm:leading-5' />
          <Link to='/events/new' class='inline-flex w-38 shadow-lg items-center justify-center px-8 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-indigo-600 hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer'>
            + Add new event
          </Link>
        </div>

        <div class='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div class='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            <InfiniteScroll dataLength={events.length} next={fetchEvents} hasMore={hasMore} loader={<div class='bg-white px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900'>Loading...</div>}>
              <table class='min-w-full'>
                <thead>
                  <tr>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Booked</th>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Name</th>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Status</th>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Coordinator</th>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Date</th>
                    <th class='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>Company</th>
                  </tr>
                </thead>
                <tbody class='bg-white'>
                  {events.map(event => (
                    <EventRow key={event._id} {...event} updateEvent={updateEvent} />
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
          {!hasMore && (
            <Link to='/events' className='w-full block text-center text-indigo-600 hover:underline cursor-pointer mt-4'>
              View Upcoming Events
            </Link>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PastEvents;
