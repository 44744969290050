// Holds all of our constants / variables for the different actions

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SELF = 'UPDATE_SELF';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_ALERT = 'SET_ALERT';
export const SET_ALERT_GENERATOR = 'SET_ALERT_GENERATOR';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL = 'REMOVE_ALL';

export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const RESET_USER = 'RESET_USER';
export const ADD_USER = 'ADD_USER';
export const UPSERT_USER = 'UPSERT_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USERS_FOR_POSITION = 'SET_USERS_FOR_POSITION';

export const SET_EVENT = 'SET_EVENT';
export const FETCH_EVENT = 'FETCH_EVENT';
export const RESET_EVENT = 'RESET_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const UPSERT_EVENT = 'UPSERT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const ADD_EVENT_SHIFT = 'ADD_EVENT_SHIFT';
export const SET_EVENT_SHIFT = 'SET_EVENT_SHIFT';
export const SET_EVENT_SHIFTS = 'SET_EVENT_SHIFTS';
export const REMOVE_EVENT_SHIFT = 'REMOVE_EVENT_SHIFT';

export const SET_POSITION = 'SET_POSITION';
export const RESET_POSITION = 'RESET_POSITION';
export const SET_POSITIONS = 'SET_POSITIONS';
export const UPSERT_POSITION = 'UPSERT_POSITION';
export const UPSERT_POSITIONS = 'UPSERT_POSITIONS';
export const DELETE_POSITION = 'DELETE_POSITION';

export const SET_LOG = 'SET_LOG';
export const RESET_LOG = 'RESET_LOG';
export const SET_LOGS = 'SET_LOGS';
export const UPSERT_LOG = 'UPSERT_LOG';
export const UPSERT_LOGS = 'UPSERT_LOGS';
export const DELETE_LOG = 'DELETE_LOG';

export const SET_SHIFT = 'SET_SHIFT';
export const RESET_SHIFT = 'RESET_SHIFT';
export const SET_SHIFTS = 'SET_SHIFTS';
export const UPSERT_SHIFT = 'UPSERT_SHIFT';
export const UPSERT_SHIFTS = 'UPSERT_SHIFTS';
export const DELETE_SHIFT = 'DELETE_SHIFT';

export const SET_AGENCY = 'SET_AGENCY';
export const RESET_AGENCY = 'RESET_AGENCY';
export const SET_AGENCIES = 'SET_AGENCIES';
export const UPSERT_AGENCY = 'UPSERT_AGENCY';
export const UPSERT_AGENCIES = 'UPSERT_AGENCIES';
export const DELETE_AGENCY = 'DELETE_AGENCY';

export const SET_INSTAWORK_EVENTS = 'SET_INSTAWORK_EVENTS';
export const ADD_INSTAWORK_EVENT = 'ADD_INSTAWORK_EVENT';
export const SET_INSTAWORK_SHIFTS = 'SET_INSTAWORK_SHIFTS';
