import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import PrivateRoute from './components/routing/PrivateRoute'

// Alerts

import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/alerts/AlertTemplate'
import AlertOptions from './components/alerts/AlertOptions'
import Alert from './components/alerts/Alert'

// Actions
import { loadUser } from './actions/auth'
import setAuthToken from './utils/setAuthToken'

// Redux
import { Provider } from 'react-redux'
import store from './store'

// COMPONENTS

// Auth
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import ResetPassword from './components/auth/ResetPassword'
import UpdatePassword from './components/auth/UpdatePassword'

import Navbar from './components/layout/Navbar/Navbar'
import { Banner } from './components/layout/Banner'
import Home from './components/layout/Home'
import WorkerTable from './components/worker/WorkerTable'
import WorkerProfile from './components/worker/WorkerProfile'
import EventTable from './components/events/EventTable'
import PastEventTable from './components/events/PastEventTable'
import Event from './components/events/Event'
import Booking from './components/bookings/Booking'
import PositionsTable from './components/positions/PositionsTable'
import Position from './components/positions/Position'
import AgenciesTable from './components/agencies/AgenciesTable'
import Agency from './components/agencies/Agency'
import YaneStaffing from './components/yane/YaneStaffing'

// Pass token from localStorage to axios header by default
if (localStorage.token) setAuthToken(localStorage.token)

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <AlertProvider class='w-1/2' template={AlertTemplate} {...AlertOptions}>
          <Alert />
          <div class='bg-gray-100'>
            <Navbar />
            <Banner />
            <main>
              <div class='mx-auto sm:px-6 lg:px-8'>
                <section>
                  <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/forgot-password' element={<ResetPassword />} />
                    <Route path='/update-password' element={<UpdatePassword />} />
                    <Route path='/yane-staffing' element={<YaneStaffing />} />
                    <Route
                      path='/home'
                      element={
                        <PrivateRoute>
                          <Home />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/workers'
                      element={
                        <PrivateRoute>
                          <WorkerTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/workers/:id'
                      element={
                        <PrivateRoute>
                          <WorkerProfile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/positions'
                      element={
                        <PrivateRoute>
                          <PositionsTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/positions/:id'
                      element={
                        <PrivateRoute>
                          <Position />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/agencies'
                      element={
                        <PrivateRoute>
                          <AgenciesTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/agencies/:id'
                      element={
                        <PrivateRoute>
                          <Agency />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/events'
                      element={
                        <PrivateRoute>
                          <EventTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/past-events'
                      element={
                        <PrivateRoute>
                          <PastEventTable />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/events/:id'
                      element={
                        <PrivateRoute>
                          <Event />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/t/events/:tid'
                      element={
                        <PrivateRoute>
                          <Event />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/bookings/:id'
                      element={
                        <PrivateRoute>
                          <Booking />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </section>
              </div>
            </main>
          </div>
        </AlertProvider>
      </Router>
    </Provider>
  )
}

export default App
