import { SET_SHIFT, SET_SHIFTS, UPSERT_SHIFT, UPSERT_SHIFTS, RESET_SHIFT, DELETE_SHIFT } from '../actions/types';

const defaultState = {
  shift: {},
  shifts: [],
};

export default (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_SHIFT:
    case UPSERT_SHIFT:
      return {
        ...state,
        shift: {
          ...payload,
        },
      };
    case UPSERT_SHIFTS:
      return {
        ...state,
        shifts: state.shifts.map((shift) => {
          const match = payload.find((s) => s._id === shift._id);
          return match || shift;
        }),
      };

    case DELETE_SHIFT:
      return {
        ...state,
        shift: { ...defaultState.shift },
        shifts: [...state.shifts.filter((shift) => shift._id !== payload.id)],
      };
    case RESET_SHIFT:
      return {
        ...state,
        shift: { ...defaultState.shift },
      };
    case SET_SHIFTS:
      return {
        ...state,
        shifts: [...payload],
      };
    default:
      return state;
  }
};
