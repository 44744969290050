import { transitions, positions } from 'react-alert';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_LEFT,
  offset: '30px',
  containerStyle: { width: ''},
  transition: transitions.FADE,
};

export default options;
