import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { setState, upsertPosition, fetchPositionById, resetPosition, deletePosition } from '../../actions/position'
import Container from '../layout/Container'
import Spinner from '../layout/Spinner'
import PositionButtons from '../positions/PositionButtons'

const Position = ({ position, setState, upsertPosition, fetchPositionById, resetPosition, deletePosition }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  // On component mount or change in id, fetch the position information from the database

  useEffect(() => {
    resetPosition()
    if (id !== 'new') fetchPositionById(id)
  }, [id, fetchPositionById, resetPosition])

  // Redirect the page from the position creation page to the position id specific page once the position has been created

  useEffect(() => {
    if (id === 'new' && position._id) navigate(`/positions/${position._id}`)
  }, [position._id])

  // On user change, update state without committing changes to the database

  const handleChange = e =>
    setState({
      ...position,
      [e.target.id]: e.target.value
    })

  // If user saves changes, update the position in the database

  const handleSubmit = async () => {
    await upsertPosition(position)
    await resetPosition()
    navigate('/positions')
  }

  // If user cancels current position editing, then clear state

  const handleCancel = async () => {
    await resetPosition()
    navigate('/positions')
  }

  const handleDelete = async () => {
    await deletePosition(position._id)
    navigate('/positions')
  }

  const header = id === 'new' ? 'Add New Position' : 'Edit Position'

  return (
    <>
      {id !== 'new' && !position._id && <Spinner />}
      <Container>
        <div>
          <h1 class='text-center text-2xl font-bold leading-loose tracking-normal text-gray-700'>{header}</h1>
          <div class='sm:col-span-6'>
            <label class='block text-xs font-medium leading-5 text-gray-700'>Position Name</label>
            <div class='mt-1 rounded-md shadow-sm'>
              <input
                id='name'
                onChange={handleChange}
                value={position.name || ''}
                class='form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              />
            </div>
          </div>
        </div>
        <PositionButtons handleSubmit={handleSubmit} handleCancel={handleCancel} handleDelete={handleDelete} />
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  position: state.position.position
})

export default connect(mapStateToProps, { setState, upsertPosition, fetchPositionById, resetPosition, deletePosition })(Position)
