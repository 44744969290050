import React from 'react';
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon, CloseIcon } from './AlertIcons';

const AlertTemplate = ({ message, options, style, close }) => {
  const configurations = {
    info: {
      icon: <InfoIcon />,
      textColor: 'text-blue-400',
      bgColor: 'bg-blue-50',
      borderColor: 'border-blue-400',
      hoverColor: 'bg-blue-100',
    },
    warning: {
      icon: <WarningIcon />,
      textColor: 'text-yellow-400',
      bgColor: 'bg-yellow-50',
      borderColor: 'border-yellow-400',
      hoverColor: 'bg-yellow-100',
    },
    success: {
      icon: <SuccessIcon />,
      textColor: 'text-green-400',
      bgColor: 'bg-green-50',
      borderColor: 'border-green-400',
      hoverColor: 'bg-green-100',
    },
    error: {
      icon: <ErrorIcon />,
      textColor: 'text-red-400',
      bgColor: 'bg-red-50',
      borderColor: 'border-red-400',
      hoverColor: 'bg-red-100',
    },
  };

  const { icon, textColor, bgColor, borderColor, hoverColor } = configurations[options.type];

  return (
    <div style={{ pointerEvents: 'all' }} class={`${bgColor} w-1/2 shadow-lg border-l-4 ${borderColor} p-4 m-2`}>
      <div class='flex'>
        <div class='flex-shrink-0'>{icon}</div>
        <div class='ml-3'>
          <p class={`text-sm leading-5 font-medium ${textColor}`}>{message}</p>
        </div>
        <div class='ml-auto pl-3'>
          <div class='-mx-1.5 -my-1.5'>
            <button onClick={close} class={`inline-flex rounded-md p-1.5 ${textColor} hover:${hoverColor} focus:outline-none focus:${hoverColor} transition ease-in-out duration-150`}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertTemplate;
