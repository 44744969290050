// Gets the token if it is there it will add it to the headers, otherwise it will delete it from the headers

import axios from '../actions/axios';

const setAuthToken = (token) => {
  if (token) axios.defaults.headers.common['x-auth-token'] = token;
  else delete axios.defaults.headers.common['x-auth-token'];
};

export default setAuthToken;
