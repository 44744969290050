import React, { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import SecondaryButton from '../../layout/Buttons/SecondaryButton';
import moment from 'moment';

const InvoiceFileList = ({ event }) => {
  const invoices = event.invoices || [];
  return (
    <div className='sm:col-span-2'>
      <dt className='text-lg leading-6 font-medium text-gray-900'>Invoices</dt>
      <dd className='mt-1 text-sm text-gray-900'>
        {!invoices.length && <div class='text-sm text-gray-700'>There have not been any invoices uploaded for this event</div>}
        {invoices.length > 0 && (
          <ul role='list' className='border border-gray-200 rounded-md divide-y divide-gray-200'>
            {invoices.map(invoice => {
              return (
                <li className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'>
                  <div className='w-0 flex-1 flex items-center'>
                    <PaperClipIcon className='flex-shrink-0 h-5 w-5 text-gray-400' aria-hidden='true' />
                    <span className='ml-2 flex-1 w-0 truncate'>
                      <div>{invoice.agency}</div>
                      <div class='text-xs text-gray-500'>{moment(invoice.date_created).format('MMMM D, YYYY [at] h:mm a')}</div>
                    </span>
                  </div>
                  <div className='ml-4 flex-shrink-0'>
                    <a href={invoice.url} target='_blank' className='font-medium text-indigo-600 hover:text-indigo-500'>
                      Download
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </dd>
    </div>
  );
};

export default InvoiceFileList;
