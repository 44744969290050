import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchYaneStaffingEvents, upsertYaneShifts, uploadInvoice } from '../../actions/yaneStaffing'
import shift from '../../reducers/shift'
import Container from '../layout/Container'
import GenericSelect from '../layout/GenericSelect'
import ShiftItem from './ShiftItem'
import moment from 'moment'
import AddShiftsModal from './AddShiftsModal'
import mongoose from 'mongoose'
const ObjectId = mongoose.Types.ObjectId

const YaneStaffing = ({ events, shifts, fetchYaneStaffingEvents, upsertYaneShifts, uploadInvoice }) => {
  useEffect(() => {
    fetchYaneStaffingEvents()
  }, [])

  const [event, setEvent] = useState({})
  const [invoice, setInvoice] = useState()

  const [currentShifts, setCurrentShifts] = useState([])

  const getEventShifts = eventId => {
    const eventShifts = shifts
      .filter(shift => shift.event._id === eventId)
      .sort((a, b) => {
        const aKey = a.position.name + moment(a.expected_start_time).format('h:mma') + moment(a.expected_end_time).format('h:mma')
        const bKey = b.position.name + moment(b.expected_start_time).format('h:mma') + moment(b.expected_end_time).format('h:mma')
        if (aKey < bKey) return -1
        else if (aKey > bKey) return 1
        else return 0
      })
    const statusComplete = eventShifts.filter(shift => shift.status === 'Complete')
    if (statusComplete.length) return statusComplete
    else return eventShifts.map(shift => ({ ...shift, _id: new ObjectId().toString(), status: 'Complete' }))
  }

  const handleChange = e => {
    const event = events.find(event => event._id === e.target.value) || {}
    setEvent(event)
    if (event._id) setCurrentShifts(getEventShifts(event._id))
    else setCurrentShifts([])
  }

  const setShift = updatedShift => {
    setCurrentShifts(currentShifts.map(shift => (shift._id === updatedShift._id ? updatedShift : shift)))
  }

  const removeShift = id => {
    setCurrentShifts(currentShifts.filter(shift => shift._id !== id))
  }

  const handleSave = () => upsertYaneShifts(currentShifts)

  const [modalState, setModalState] = useState(false)

  const handleFileChange = async e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64encoded = reader.result
      console.log(base64encoded)
      uploadInvoice({
        file: base64encoded,
        eventId: event._id,
        agency: 'Yane Staffing',
        filename: `${event.name} Invoice (${moment().format('MM-DD-YYYY')}).pdf`,
        attributes: file,
        action: 'upload-file'
      })
      setInvoice(file)
    }
    reader.readAsDataURL(file)
  }

  return (
    <Container>
      <div>
        {modalState && <AddShiftsModal event={event} shifts={currentShifts} setModalState={setModalState} setCurrentShifts={setCurrentShifts} />}
        <h1 class='text-2xl text-gray-700 font-bold leading-loose text-center tracking-normal'>Yane Staffing</h1>
        <p class='text-sm mb-2 font-medium text-gray-700'>Select a PO#</p>
        <GenericSelect id='' onChange={handleChange} value={event} options={events} />
        {event._id && (
          <div>
            <h2 class='text-lg font-medium text-gray-700 mt-6'>Shifts</h2>
            {currentShifts.map((shift, i) => {
              return <ShiftItem index={i} shift={shift} setShift={setShift} removeShift={removeShift} event={event} />
            })}
            <div class='text-indigo-600 mt-2 cursor-pointer hover:text-indigo-500' onClick={() => setModalState(true)}>
              + Add Shift
            </div>
            <div>
              <h2 class='text-lg font-medium text-gray-700 mt-6 mb-2'>Invoice</h2>
              {invoice && <div class='text-indigo-600 text-sm mt-2 cursor-pointer hover:text-indigo-500'>{invoice.name}</div>}
              {!invoice && (
                <div class='mt-1 sm:mt-0 sm:col-span-2'>
                  <div class='max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                    <div class='space-y-1 text-center'>
                      <svg class='mx-auto h-12 w-12 text-gray-400' stroke='currentColor' fill='none' viewBox='0 0 48 48' aria-hidden='true'>
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                      <div class='flex text-sm text-gray-600'>
                        <label
                          for='file-upload'
                          class='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <span>Upload an invoice</span>
                          <input id='file-upload' name='file-upload' type='file' class='sr-only' accept='.pdf' onChange={handleFileChange}></input>
                        </label>
                      </div>
                      <p class='text-xs text-gray-500'>PDF up to 10MB</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div class='mt-8'>
              <button
                type='submit'
                onClick={handleSave}
                class='bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-10 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600'
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

const mapStateToProps = state => ({
  events: state.event.events,
  shifts: state.shift.shifts
})

export default connect(mapStateToProps, { fetchYaneStaffingEvents, upsertYaneShifts, uploadInvoice })(YaneStaffing)
