import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getInstaworkEvents } from '../../actions/instawork';
import Select, { components } from 'react-select';

// value = { _id: 302242304238aff }

const InstaworkEventsSelect = ({ id, value = {}, onChange, events, getInstaworkEvents, isClearable, customStyles = {}, options = [] }) => {
  let optionsList = [...events, ...options];

  useEffect(() => {
    if (!events.length) getInstaworkEvents();
  }, [getInstaworkEvents]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      ...(customStyles.control || {}),
      'outline': 'none',
      'boxShadow': isFocused ? '0 0 0 3px rgba(164, 202, 254, 0.45)' : 'none',
      'borderColor': isFocused ? '#a4cafe' : styles.borderColor,
      ':hover': {
        borderColor: isFocused ? '#a4cafe' : '#d2d6dc',
      },
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'lightgray',
        color: 'black',
      },
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    singleValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    multiValue: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    option: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  };

  const handleChange = (e) => {
    onChange({
      target: { id, value: e },
    });
  };

  const Option = (props) => {
    console.log(props);
    return (
      <>
        <components.Option {...props}>
          <div class='text-gray-900 font-medium'>{props.children}</div>
          <div class='text-gray-700 text-xs italic'>
            {props.data.date} {props.data.time}
          </div>
        </components.Option>
      </>
    );
  };

  const valueObj = optionsList.find((option) => option.value === value._id);

  return <Select value={valueObj} onChange={handleChange} options={optionsList} styles={styles} isClearable={isClearable} isLoading={!optionsList.length} components={{ Option }} />;
};

const mapStateToProps = (state) => ({
  events: state.instawork.events,
});

export default connect(mapStateToProps, { getInstaworkEvents })(InstaworkEventsSelect);
