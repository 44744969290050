import axios from './axios'
import { SET_EVENT, SET_INSTAWORK_EVENTS, ADD_INSTAWORK_EVENT, SET_INSTAWORK_SHIFTS } from './types'
import { setAlert } from './alert'
import * as tripleseat from './tripleseat'
import { fetchShifts } from './shift'
import moment from 'moment'
import { transform, isEqual, isArray, isObject, initial } from 'lodash'
import { difference } from '../utils/functions'
import { upsertEvent } from './event'

export const createInstaworkEvent = event => async dispatch => {
  try {
    const eventDetails = {
      eventName: event.name,
      eventDate: moment(event.event_date).format('MM/DD/YYYY'),
      address: event.address,
      locationInstructions: '',
      parkingType: 'Free' // Free | Available, not free | No parking
    }

    const res = await axios.post(`/api/instawork`, { action: 'create-event', eventDetails })
    const id = res.data.id
    if (id) {
      dispatch(
        upsertEvent({
          ...event,
          iw_event_id: id
        })
      )
      dispatch({
        type: ADD_INSTAWORK_EVENT,
        payload: {
          _id: id,
          value: id,
          label: event.name,
          name: event.name,
          date: moment(event.event_date).format('dddd, MMMM D')
        }
      })
    } else {
      throw new Error(res.data.error)
    }
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const getInstaworkEvents = () => async dispatch => {
  try {
    const res = await axios.post('/api/instawork', { action: 'get-events' })
    const events = res.data
    dispatch({
      type: SET_INSTAWORK_EVENTS,
      payload: events
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchInstaworkShifts = eventId => async dispatch => {
  try {
    if (!eventId) {
      dispatch({
        type: SET_INSTAWORK_SHIFTS,
        payload: []
      })
    } else {
      const res = await axios.post('/api/instawork', { action: 'get-shifts', eventId })
      const shifts = res.data
      dispatch({
        type: SET_INSTAWORK_SHIFTS,
        payload: shifts
      })
    }
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
