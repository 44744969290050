import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../actions/auth';

const ResetPassword = ({ resetPassword }) => {
  const [state, setState] = useState({ emailTriggered: false });

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(state.email);
    setState({
      ...state,
      emailTriggered: true,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div class='relative flex flex-col md:py-12 sm:px-6 lg:px-8'>
      <div class='z-10 mt-24 sm:mt-40 sm:mx-auto sm:w-full sm:max-w-md'>
        <div class='bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10'>
          <div class='flex items-center justify-center'>
            <div class='max-w-md w-full'>
              <h2 class='mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900'>Reset your password</h2>
              {!state.emailTriggered && (
                <div>
                  <div class='mt-3 text-center text-gray-500'>Please enter your email below and we'll send you instructions to reset your password.</div>
                  <form class='mt-8' onSubmit={handleSubmit} method='POST'>
                    <div class='rounded-md shadow-sm'>
                      <div>
                        <input
                          aria-label='Email address'
                          name='email'
                          type='email'
                          required
                          class='appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5'
                          placeholder='Email address'
                          onChange={handleChange}
                          value={state.email || ''}
                        />
                      </div>
                    </div>

                    <div class='mt-6'>
                      <button
                        type='submit'
                        class='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
                      >
                        Send password reset email
                      </button>
                      <Link to='/login' class='block text-center text-indigo-700 mt-4 mr-4'>
                        <svg viewBox='0 0 448 512' class='w-5 h-5 inline-flex mr-2 fill-current text-indigo-700'>
                          <path
                            fill='currentColor'
                            d='M193.456 357.573L127.882 292H424c13.255 0 24-10.745 24-24v-24c0-13.255-10.745-24-24-24H127.882l65.574-65.573c9.373-9.373 9.373-24.569 0-33.941l-16.971-16.971c-9.373-9.373-24.569-9.373-33.941 0L7.029 239.029c-9.373 9.373-9.373 24.568 0 33.941l135.515 135.515c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.372 9.373-24.568 0-33.941z'
                            class=''
                          ></path>
                        </svg>
                        Return to sign in
                      </Link>
                    </div>
                  </form>
                </div>
              )}
              {state.emailTriggered && (
                <div>
                  <div class='mt-8 text-center text-gray-800'>If that email address is in our system, we have emailed you instructions to reset your password. Thank you</div>
                  <Link
                    to='/login'
                    class='mt-8 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
                  >
                    Return to sign in
                  </Link>
                </div>
              )}
            </div>
          </div>
          <p class='mt-6 block text-center text-sm font-medium leading-5 text-gray-700'></p>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { resetPassword })(ResetPassword);
