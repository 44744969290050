import axios from './axios'
import { SET_POSITION, SET_POSITIONS, UPSERT_POSITION, UPSERT_POSITIONS, RESET_POSITION, DELETE_POSITION } from './types'
import { setAlert } from './alert'

export const fetchPositions = filter => async dispatch => {
  try {
    const res = await axios.get('/api/position', { params: filter })
    dispatch({
      type: SET_POSITIONS,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchPosition = filter => async dispatch => {
  try {
    const res = await axios.get('/api/position', { params: filter })
    dispatch({
      type: SET_POSITION,
      payload: res.data[0]
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const fetchPositionById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/position/${id}`)
    dispatch({
      type: SET_POSITION,
      payload: res.data
    })
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const setState = position => async dispatch => {
  dispatch({
    type: SET_POSITION,
    payload: position
  })
}

export const setPositions = positions => async dispatch => {
  dispatch({
    type: SET_POSITIONS,
    payload: positions
  })
}

export const resetPosition = () => async dispatch => {
  dispatch({
    type: RESET_POSITION
  })
}

export const upsertPosition = position => async dispatch => {
  try {
    await axios.post(`/api/position`, position)
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const upsertPositions = positions => async dispatch => {
  try {
    let res = await Promise.all(positions.map(async position => await axios.post(`/api/position`, position)))
    let data = res.map(r => r.data)
    console.log(res)
    dispatch({
      type: UPSERT_POSITIONS,
      payload: data
    })
    dispatch(setAlert('success', 'Success! The changes were successfully saved.'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}

export const deletePosition = id => async dispatch => {
  try {
    await axios.delete(`/api/position/${id}`)
    dispatch({
      type: DELETE_POSITION,
      payload: { id }
    })
    dispatch(setAlert('success', 'Successfully deleted the event'))
  } catch (err) {
    if (err.response) dispatch(setAlert('error', err.response.data.msg))
  }
}
